import "./index.less";
import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: 45.51188186998427,
  lng: -73.62454104570993,
};

export const SalesOfficeLocation = React.memo(() => {
  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={12} options={{ zoomControl: false, disableDefaultUI: true, mapTypeControl: false, streetViewControl: false, minZoom: 12 }}>
        {/* Child components, such as markers, info windows, etc. */}
        <Marker visible position={center} />
      </GoogleMap>
    </LoadScript>
  );
});
