import { useNavigate, useSearchParams } from "react-router-dom";

export const useRouting = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const toProfileView = () => {
    return `/profile`;
  };
  const toContactUsView = () => {
    return `/contact-us`;
  };
  const toFrequentlyAskedQuestionsView = () => {
    return `/frequently-asked-questions`;
  };
  const toContractsView = () => {
    return `/contracts`;
  };
  const toContractChoicesView = (contractId: string) => {
    return `/contracts/${contractId}/choices`;
  };
  const toContractChoiceView = (contractId: string, choiceId: string) => {
    return `/contracts/${contractId}/choices/${choiceId}`;
  };
  const toContractChoiceUpdateView = (contractId: string, choiceId: string) => {
    return `/contracts/${contractId}/choices/${choiceId}/update`;
  };
  const toContractChoiceConfirmView = (contractId: string, choiceId: string) => {
    return `/contracts/${contractId}/choices/${choiceId}/confirm`;
  };
  const toInvoicesView = (searchParams?: { contractIds?: string[] }) => {
    let url = `/invoices`;
    if (searchParams) {
      const init: string[][] = [];
      if (searchParams.contractIds && searchParams.contractIds.length !== 0) {
        init.push(["contractIds", searchParams.contractIds.join(",")]);
      }
      url += `?${new URLSearchParams(init).toString()}`;
    }
    return url;
  };
  const toInvoiceView = (invoiceId: string) => {
    return `/invoices/${invoiceId}`;
  };
  return {
    searchParams,
    navigate,
    toProfileView,
    toContactUsView,
    toFrequentlyAskedQuestionsView,
    toContractsView,
    toContractChoicesView,
    toContractChoiceView,
    toContractChoiceUpdateView,
    toContractChoiceConfirmView,
    toInvoicesView,
    toInvoiceView,
  };
};
