import "./index.less";
import { useDownloadCustomerContractUnityWebGLMutation } from "graphql/mutations";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Unity, UnityConfig, useUnityContext } from "react-unity-webgl";

type Props = {
  readonly projectModelRoomId: string;
  readonly customerChoiceItems: {
    readonly projectModelElementId: string;
    readonly projectModelElementOptionId: string | null;
  }[]; // TODO:
};

export const ProjectModelUnityWebGL: FunctionComponent<Props & { customerContractId: string }> = ({ customerContractId, projectModelRoomId, customerChoiceItems }) => {
  const [unityConfig, setUnityConfig] = useState<UnityConfig | null>(null);
  const { downloadCustomerContractUnityWebGL } = useDownloadCustomerContractUnityWebGLMutation(
    (loaderUrl, dataUrl, frameworkUrl, codeUrl) => {
      setUnityConfig({
        loaderUrl,
        dataUrl,
        frameworkUrl,
        codeUrl,
      });
    },
    (errors) => {}
  );
  useEffect(() => {
    downloadCustomerContractUnityWebGL(customerContractId);
    // eslint-disable-next-line
  }, []);
  if (unityConfig) {
    return <Component unityConfig={unityConfig} projectModelRoomId={projectModelRoomId} customerChoiceItems={customerChoiceItems} />;
  }
  return <div className="project-model-unity-webgl" />;
};

export const Component: FunctionComponent<
  Props & {
    unityConfig: UnityConfig;
  }
> = ({ unityConfig, projectModelRoomId, customerChoiceItems }) => {
  const [unityIsLoaded, setUnityIsLoaded] = useState<boolean>(false);
  const { unityProvider, sendMessage, isLoaded, addEventListener, removeEventListener, UNSAFE__detachAndUnloadImmediate: detachAndUnloadImmediate } = useUnityContext(unityConfig);
  useEffect(() => {
    return () => {
      if (isLoaded) {
        detachAndUnloadImmediate();
      }
    };
  }, [isLoaded, detachAndUnloadImmediate]);
  useEffect(() => {
    if (unityIsLoaded) {
      sendMessage(
        "ContractChoiceManager",
        "UpdateItems",
        JSON.stringify(
          customerChoiceItems
            .filter((customerChoiceItem) => customerChoiceItem.projectModelElementOptionId !== null)
            .map((customerChoiceItem) => ({
              projectModelElementId: customerChoiceItem.projectModelElementId,
              projectModelElementOptionId: customerChoiceItem.projectModelElementOptionId,
            }))
        )
      );
    }
    // eslint-disable-next-line
  }, [unityIsLoaded, sendMessage, JSON.stringify(customerChoiceItems)]);
  useEffect(() => {
    if (isLoaded) {
      if (projectModelRoomId === "") {
        sendMessage("CameraManager", "ShowDefault");
      } else {
        sendMessage("CameraManager", "ShowProjectModelRoom", projectModelRoomId);
      }
    }
  }, [isLoaded, projectModelRoomId, sendMessage]);
  const handler = useCallback(() => {
    setUnityIsLoaded(true);
  }, [setUnityIsLoaded]);
  useEffect(() => {
    addEventListener("UnityIsLoaded", handler);
    return () => {
      removeEventListener("UnityIsLoaded", handler);
    };
  }, [addEventListener, removeEventListener, handler]);
  return <Unity className="project-model-unity-webgl" unityProvider={unityProvider} />;
};
