import { View, ViewFilters } from "buildingBlocks";
import { CustomerContractInvoicePanel } from "components";
import { useAppContext } from "hooks";
import { FunctionComponent } from "react";
import { useSearchParams } from "react-router-dom";
import { useList } from "react-use";
import { CheckPicker } from "rsuite";

export const InvoicesView: FunctionComponent = () => {
  const [searchParams] = useSearchParams();
  const [contractIds, { set: setContractIds }] = useList<string>(searchParams.get("contractIds")?.split(",") ?? []);
  const { customerContracts, customerContractInvoices, customerContractPayments, projects, projectUnits } = useAppContext();
  return (
    <View title="Mes factures">
      <ViewFilters>
        <CheckPicker
          style={{
            width: "250px",
          }}
          value={contractIds}
          data={customerContracts.map((customerContract) => {
            const project = projects.find((project) => project.id === customerContract.projectId)!;
            const projectUnit = projectUnits.find((projectUnit) => projectUnit.id === customerContract.projectUnitId)!;
            return {
              label: `${project.name} (Unité #${projectUnit.number})`,
              value: customerContract.id,
            };
          })}
          onChange={setContractIds}
          label="Contrats"
        />
      </ViewFilters>
      {customerContractInvoices
        .filter((customerContractInvoice) =>
          contractIds.length !== 0 ? (customerContractInvoice.customerContractId ? contractIds.includes(customerContractInvoice.customerContractId) : false) : true
        )
        .map((customerContractInvoice) => (
          <CustomerContractInvoicePanel key={customerContractInvoice.id} customerContractInvoice={customerContractInvoice} customerContractPayments={customerContractPayments} />
        ))}
    </View>
  );
};
