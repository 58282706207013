import "./index.less";
import { useCustomerContractChoiceContext, useCustomerContractContext, useRouting } from "hooks";
import { FunctionComponent } from "react";
import { CustomerChoiceUpdateForm } from "forms";
import { View } from "buildingBlocks";
import { useUpdateCustomerContractChoiceItemsMutation } from "graphql/mutations";
import { useList } from "react-use";

export const ContractChoiceUpdateView: FunctionComponent = () => {
  const { navigate, toContractsView } = useRouting();
  const { customerContract } = useCustomerContractContext();
  const { customerContractChoice } = useCustomerContractChoiceContext();
  const [errors, { set: setErrors }] = useList<string>([]);
  const { updateCustomerContractChoiceItems, updateCustomerContractChoiceItemsLoading } = useUpdateCustomerContractChoiceItemsMutation(() => {}, setErrors);
  return (
    <View id="contract-choice-update-view" fluid>
      <CustomerChoiceUpdateForm
        customerContract={customerContract}
        loading={updateCustomerContractChoiceItemsLoading}
        errors={errors}
        onSubmit={async (values) => {
          await updateCustomerContractChoiceItems(customerContract.id, customerContractChoice.id, {
            projectModelVibeId: values.projectModelVibeId,
            items: values.items.map((item) => ({
              projectModelElementId: item.projectModelElementId,
              projectModelElementOptionId: item.projectModelElementOptionId,
            })),
          });
        }}
        onCancel={() => {
          navigate(toContractsView());
        }}
      />
    </View>
  );
};
