import "./index.less";
import { Fragment, FunctionComponent } from "react";
import { CustomerContract } from "graphql/__generated__/CustomerContract";
import { useAppContext, useProjectContext, useProjectUnitContext, useRouting } from "hooks";
import { Button, Steps } from "rsuite";
import { TagWithPopover } from "buildingBlocks";
import { CustomerContractInvoice } from "graphql/__generated__/CustomerContractInvoice";
import { CustomerContractChoice } from "graphql/__generated__/CustomerContractChoice";
import { useDownloadCustomerContractMutation } from "graphql/mutations";

type Props = {
  readonly customerContract: CustomerContract;
  readonly showList?: boolean;
};

export const CustomerContractPanel: FunctionComponent<Props> = ({ customerContract, showList = false }) => {
  const { navigate, toContactUsView } = useRouting();
  const { customerContractInvoices } = useAppContext();
  const { project } = useProjectContext(customerContract.projectId);
  const { projectUnit } = useProjectUnitContext(customerContract.projectUnitId);
  return (
    <div className="customer-contract-panel panel">
      <div className="panel-header">
        <section className="panel-title">
          <span>{project.name}</span>
          <h2>Unité #{projectUnit.number}</h2>
        </section>
        <section className="panel-status">
          <div>
            <PanelStatus customerContract={customerContract} />
          </div>
        </section>
        {!customerContract.isCustomized && (
          <section className="panel-balance">
            {!customerContract.isCustomized && (
              <>
                <label>Solde dû</label>
                <span>{Number(customerContract.balance).toCurrencyFormat("CAD")}</span>
              </>
            )}
          </section>
        )}
        {customerContract.isCustomized && (
          <div className="panel-acitons">
            <Button
              size="sm"
              appearance="primary"
              onClick={() => {
                navigate(toContactUsView());
              }}
            >
              Nous joindre
            </Button>
          </div>
        )}
      </div>
      {showList && !customerContract.isCanceled && !customerContract.isCustomized && (
        <PanelBody customerContract={customerContract} customerContractChoices={customerContract.choices} customerContractInvoices={customerContractInvoices} />
      )}
    </div>
  );
};

const PanelStatus: FunctionComponent<{ customerContract: CustomerContract }> = ({ customerContract }) => {
  if (customerContract.isCanceled) {
    return (
      <TagWithPopover
        title="ANNULÉ"
        content="Votre contrat a été annulé. Si vous avez effectué un paiement en lien avec la personnalisation de votre unité, veuillez contacter votre gestionnaire de compte client au numéro de téléphone suivant: (514) 876-9900."
        color="red"
      />
    );
  } else if (customerContract.isCustomized) {
    return <TagWithPopover title="EN PERSONNALISATION" content="Votre unité est en personnalisation, pour faire vos choix vous devez contacter un gestionnaire client." color="blue" />;
  } else if (!customerContract.projectModelId) {
    return (
      <TagWithPopover
        title="EN ATTENTE"
        content="Votre unité n'a pas encore été configurée par l'équipe de Dév Méta. Vous serez notifié par courriel lorsque vous pourrez faire vos choix."
        color="yellow"
      />
    );
  } else {
    return <></>;
  }
};

const PanelBody: FunctionComponent<{
  customerContract: CustomerContract;
  customerContractChoices: ReadonlyArray<CustomerContractChoice>;
  customerContractInvoices: ReadonlyArray<CustomerContractInvoice>;
}> = ({ customerContract, customerContractChoices, customerContractInvoices }) => {
  const { navigate, toInvoiceView, toContractChoiceView, toContractChoiceUpdateView, toContractChoiceConfirmView } = useRouting();
  const { downloadCustomerContract } = useDownloadCustomerContractMutation(
    (contractUrl) => {
      const link = document.createElement("a");
      link.href = contractUrl;
      link.click();
    },
    (errors) => {
      console.error(errors);
    }
  );
  return (
    <div className="panel-body">
      {customerContractChoices.map((customerContractChoice, index) => {
        const currentStep = getCurrentStep(customerContractChoice, customerContractInvoices);
        const customerContractInvoice = customerContractInvoices.find((customerContractInvoice) => customerContractInvoice.customerContractChoiceId === customerContractChoice.id);
        return (
          <Fragment key={customerContractChoice.id}>
            <div className="customer-contract-choice">
              <div className="customer-contract-choice-body">
                <div className="customer-contract-choice-title">
                  <span>
                    {new Date(customerContractChoice.createdAt).toLocaleString("fr-CA", {
                      year: "numeric",
                      day: "2-digit",
                      month: "long",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                  <h2>{customerContractChoice.label}</h2>
                </div>
                <Steps current={currentStep}>
                  <Steps.Item title="Mes choix" />
                  <Steps.Item title="Confirmation" />
                  {customerContractInvoice && Number(customerContractInvoice.total) < 0 ? <Steps.Item title="Remboursement" /> : <Steps.Item title="Paiement" />}
                </Steps>
              </div>
              <div className="bar"></div>
              <div className="customer-contract-choice-actions">
                <Button
                  size="xs"
                  appearance="primary"
                  onClick={() => {
                    downloadCustomerContract(customerContract.id);
                  }}
                >
                  Télécharger mon contrat
                </Button>
                {customerContract.projectModelId !== null && customerContractChoice.isDraft && customerContractChoice.items.length === 0 && (
                  <Button
                    size="xs"
                    appearance="primary"
                    onClick={() => {
                      navigate(toContractChoiceUpdateView(customerContract.id, customerContractChoice.id));
                    }}
                  >
                    Faire mes choix
                  </Button>
                )}
                {!customerContractChoice.isDraft && customerContractChoice.items.length !== 0 && (
                  <Button
                    size="xs"
                    appearance="primary"
                    onClick={() => {
                      navigate(toContractChoiceView(customerContract.id, customerContractChoice.id));
                    }}
                  >
                    Voir mon choix
                  </Button>
                )}
                {customerContractChoice.isDraft && customerContractChoice.items.length !== 0 && (
                  <Button
                    size="xs"
                    onClick={() => {
                      navigate(toContractChoiceUpdateView(customerContract.id, customerContractChoice.id));
                    }}
                  >
                    Modifier mes choix
                  </Button>
                )}
                {customerContractChoice.isDraft && customerContractChoice.items.length !== 0 && (
                  <Button
                    size="xs"
                    appearance="primary"
                    onClick={() => {
                      navigate(toContractChoiceConfirmView(customerContract.id, customerContractChoice.id));
                    }}
                  >
                    Confirmer mes choix
                  </Button>
                )}
                {customerContractInvoice && (
                  <Button
                    size="xs"
                    appearance="primary"
                    onClick={() => {
                      navigate(toInvoiceView(customerContractInvoice.id));
                    }}
                  >
                    Voir ma facture
                  </Button>
                )}
                {customerContractInvoice && Number(customerContractInvoice.balance) > 0 && (
                  <Button size="xs" appearance="primary" disabled>
                    Effectuer un paiement
                  </Button>
                )}
              </div>
            </div>
            {index !== customerContractChoices.length - 1 && <hr />}
          </Fragment>
        );
      })}
    </div>
  );
};

const getCurrentStep = (customerContractChoice: CustomerContractChoice, customerContractInvoices: ReadonlyArray<CustomerContractInvoice>) => {
  const customerContractInvoice = customerContractInvoices.find((customerContractInvoice) => customerContractInvoice.customerContractChoiceId === customerContractChoice.id)!;
  if (customerContractChoice.isDraft && customerContractChoice.items.length === 0) {
    return 0;
  } else if (customerContractChoice.isDraft && customerContractChoice.items.length !== 0) {
    return 1;
  } else if (!customerContractChoice.isDraft && Number(customerContractInvoice.balance) !== 0) {
    return 2;
  } else {
    return 3;
  }
};
