import "./index.less";
import { useCustomerContractChoiceContext, useCustomerContractContext, useRouting } from "hooks";
import { FunctionComponent } from "react";
import { CustomerChoiceConfirmForm } from "forms";
import { CustomerContractPanel } from "components";
import { View } from "buildingBlocks";
import { useConfirmCustomerChoiceItemsMutation } from "graphql/mutations";
import { useList } from "react-use";

export const ContractChoiceConfirmView: FunctionComponent = () => {
  const { navigate, toInvoiceView } = useRouting();
  const { customerContract } = useCustomerContractContext();
  const { customerContractChoice } = useCustomerContractChoiceContext();
  const [errors, { set: setErrors }] = useList<string>([]);
  const { confirmCustomerContractChoiceItems, confirmCustomerContractChoiceItemsLoading } = useConfirmCustomerChoiceItemsMutation((customerContractInvoiceId) => {
    navigate(toInvoiceView(customerContractInvoiceId));
  }, setErrors);
  return (
    <View id="contract-choice-confirm-view" title="Confirmation">
      <CustomerContractPanel customerContract={customerContract} />
      <CustomerChoiceConfirmForm
        customerContract={customerContract}
        loading={confirmCustomerContractChoiceItemsLoading}
        errors={errors}
        onSubmit={async () => {
          await confirmCustomerContractChoiceItems(customerContract.id, customerContractChoice.id);
        }}
      />
    </View>
  );
};
