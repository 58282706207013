import "./index.less";
import { FunctionComponent } from "react";
import { ProjectModel } from "graphql/__generated__/ProjectModel";
import { ProjectUnit } from "graphql/__generated__/ProjectUnit";
import { Project } from "graphql/__generated__/Project";
import { CustomerContract } from "graphql/__generated__/CustomerContract";

type Props = {
  readonly title?: string;
  readonly className?: string;
  readonly customerContract: CustomerContract;
  readonly customerContractChoiceId: string;
  readonly project: Project;
  readonly projectModels: ReadonlyArray<ProjectModel>;
  readonly projectUnits: ReadonlyArray<ProjectUnit>;
};

export const CustomerContractInfos: FunctionComponent<Props> = ({ title = undefined, className = undefined, customerContract, customerContractChoiceId, project, projectModels, projectUnits }) => {
  const customerContractChoice = customerContract.choices.find((customerContractChoice) => customerContractChoice.id === customerContractChoiceId);
  const projectModel = projectModels.find((projectModel) => projectModel.id === customerContract.projectModelId);
  const projectModelVibe = projectModel?.vibes.find((projectModelVibe) => projectModelVibe.id === customerContractChoice?.projectModelVibeId);
  const projectUnit = projectUnits.find((projectUnit) => projectUnit.id === customerContract.projectUnitId);
  return (
    <div className={`project-contract-infos${className ? ` ${className}` : ""}`}>
      {title && <div className="title">{title}</div>}
      <div className="panel">
        <div className="title">Informations</div>
        <div className="row">
          <div className="column">Projet</div>
          <div className="column">{project.name}</div>
        </div>
        <div className="row">
          <div className="column">Unité #</div>
          <div className="column">{projectUnit ? projectUnit.number : "--"}</div>
        </div>
        <div className="row">
          <div className="column">Étage</div>
          <div className="column">{projectUnit ? projectUnit.floor : "--"}</div>
        </div>
        <div className="row">
          <div className="column">Modèle</div>
          <div className="column">{projectModel ? projectModel.name : "--"}</div>
        </div>
        <div className="row">
          <div className="column">Ambiance</div>
          <div className="column">{projectModelVibe ? projectModelVibe.name : "--"}</div>
        </div>
        {customerContractChoice && (
          <>
            <div className="row">
              <div className="column">Révision</div>
              <div className="column">{customerContractChoice.label}</div>
            </div>
            <div className="row">
              <div className="column">Créer le</div>
              <div className="column">
                {new Date(customerContractChoice.createdAt).toLocaleString("fr-CA", {
                  year: "numeric",
                  day: "2-digit",
                  month: "long",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </div>
            </div>
            <div className="row">
              <div className="column">Modifié le</div>
              <div className="column">
                {new Date(customerContractChoice.updatedAt).toLocaleString("fr-CA", {
                  year: "numeric",
                  day: "2-digit",
                  month: "long",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
