declare global {
  interface Number {
    toCurrencyFormat(currencyIsoCode: "CAD"): string;
  }
}

// eslint-disable-next-line
Number.prototype.toCurrencyFormat = function (currencyIsoCode) {
  return this.toLocaleString("fr-CA", {
    style: "currency",
    currency: currencyIsoCode,
  });
};

export {};
