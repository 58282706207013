import "./index.less";
import { FunctionComponent } from "react";
import { CustomerContract } from "graphql/__generated__/CustomerContract";
import { ProjectModel } from "graphql/__generated__/ProjectModel";
import { CustomerContractChoice } from "graphql/__generated__/CustomerContractChoice";

type Props = {
  readonly projectModel: ProjectModel;
  readonly customerContract: CustomerContract;
  readonly currentCustomerContractChoice: CustomerContractChoice;
  readonly value: {
    readonly projectModelElementId: string;
    readonly projectModelElementOptionId: string | null;
  };
};

export const ReviewPriceByPreviousCustomerChoiceItem: FunctionComponent<Props> = ({ projectModel, customerContract, currentCustomerContractChoice, value }) => {
  let amount: number = 0;
  const previousCustomerContractChoice = customerContract.choices.find((customerContractChoice) => customerContractChoice.id === customerContract.previousChoiceId);
  if (previousCustomerContractChoice) {
    const previousCustomerContractChoiceItem = previousCustomerContractChoice.items.find(
      (previousCustomerContractChoiceItem) => previousCustomerContractChoiceItem.projectModelElementId === value.projectModelElementId
    );
    if (previousCustomerContractChoiceItem && previousCustomerContractChoiceItem.projectModelElementOptionId !== value.projectModelElementOptionId) {
      amount += currentCustomerContractChoice.billingSettings.customOptionUnitPrice;
      const projectModelElement = projectModel.elements.find((projectModelElement) => projectModelElement.id === value.projectModelElementId);
      if (projectModelElement) {
        const previousProjectModelElementOption = projectModelElement.options.find(
          (projectModelElementOption) => projectModelElementOption.id === previousCustomerContractChoiceItem.projectModelElementOptionId
        );
        if (previousProjectModelElementOption && previousProjectModelElementOption.isExtra) {
          const previousProjectModelElementOptionPrice = previousProjectModelElementOption.prices.find(
            (previousProjectModelElementOptionPrice) => previousProjectModelElementOptionPrice.id === previousCustomerContractChoiceItem.projectModelElementOptionPriceId
          );
          if (previousProjectModelElementOptionPrice) {
            amount -= Number(previousProjectModelElementOptionPrice.amount);
          }
        }
        const projectModelElementOption = projectModelElement.options.find((projectModelElementOption) => projectModelElementOption.id === value.projectModelElementOptionId);
        if (projectModelElementOption && projectModelElementOption.isExtra) {
          amount += projectModelElementOption.price ?? 0;
        }
      }
      return <span>{Number(amount).toCurrencyFormat("CAD")}</span>;
    }
  }
  return <span>PRÉRÉVISION</span>;
};
