import "./index.less";
import { FunctionComponent } from "react";
import { ProjectModelVibe } from "graphql/__generated__/ProjectModelVibe";
import { ProjectModel } from "graphql/__generated__/ProjectModel";
import { CustomerContractChoice } from "graphql/__generated__/CustomerContractChoice";

type Props = {
  readonly projectModel: ProjectModel;
  readonly projectModelVibe: ProjectModelVibe;
  readonly currentCustomerContractChoice: CustomerContractChoice;
  readonly value: {
    readonly projectModelElementId: string;
    readonly projectModelElementOptionId: string | null;
  };
};

export const ReviewPriceByProjectModelVibeItem: FunctionComponent<Props> = ({ projectModel, projectModelVibe, currentCustomerContractChoice, value }) => {
  let amount: number = 0;
  const projectModelElement = projectModel.elements.find((projectModelElement) => projectModelElement.id === value.projectModelElementId);
  if (projectModelElement) {
    const projectModelElementOption = projectModelElement.options.find((projectModelElementOption) => projectModelElementOption.id === value.projectModelElementOptionId);
    if (projectModelElementOption) {
      const projectModelVibeItem = projectModelVibe.items.find((projectModelVibeItem) => projectModelVibeItem.projectModelElementId === value.projectModelElementId);
      if (projectModelVibeItem && projectModelVibeItem.projectModelElementOptionId !== value.projectModelElementOptionId) {
        if (projectModelElementOption.isExtra) {
          amount += projectModelElementOption.price ?? 0;
        } else {
          amount += currentCustomerContractChoice.billingSettings.customOptionUnitPrice;
        }
      }
    }
    if (amount !== 0) {
      return <span>{amount.toCurrencyFormat("CAD")}</span>;
    }
    if (projectModelElement.isBoolean) {
      return <span>EXCLU</span>;
    }
  }
  return <span>INCLUT</span>;
};
