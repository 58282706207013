import "./index.less";
import { FunctionComponent, Fragment, useState } from "react";
import { Animation, Toggle, Tag } from "rsuite";
import { ProjectModelElement } from "graphql/__generated__/ProjectModelElement";
import { ProjectProduct } from "graphql/__generated__/ProjectProduct";
import { ProjectProductCategory } from "graphql/__generated__/ProjectProductCategory";
import produce from "immer";
import { ProjectModelElementOptionPanel, ProjectModelElementPrice } from "components";
import ArrowDownLineIcon from "@rsuite/icons/ArrowDownLine";
import ArrowUpLineIcon from "@rsuite/icons/ArrowUpLine";
import { CustomerChoiceItem } from "types";
import { ProjectModel } from "graphql/__generated__/ProjectModel";
import { CustomerContract } from "graphql/__generated__/CustomerContract";

type Props = {
  readonly customerContract: CustomerContract;
  readonly projectModel: ProjectModel;
  readonly projectModelVibeId: string;
  readonly projectProducts: ProjectProduct[];
  readonly projectProductCategories: ProjectProductCategory[];
  readonly projectModelElement: ProjectModelElement;
  readonly customerChoiceItems?: CustomerChoiceItem[] | undefined;
  readonly setCustomerChoiceItems?: ((customerChoiceItems: CustomerChoiceItem[]) => void) | undefined;
};

export const ProjectModelElementPanel: FunctionComponent<Props> = ({
  customerContract,
  projectProducts,
  projectProductCategories,
  projectModelElement,
  projectModelVibeId,
  projectModel,
  customerChoiceItems,
  setCustomerChoiceItems,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const customerChoiceItem = customerChoiceItems?.find((x) => x.projectModelElementId === projectModelElement.id) ?? null;
  const projectModelElementOption = projectModelElement.options.find((projectModelElementOption) => projectModelElementOption.id === customerChoiceItem?.projectModelElementOptionId)!;
  const projectModelVibeItem = projectModel.vibes
    .find((projectModelVibe) => projectModelVibe.id === projectModelVibeId)!
    .items.find((projectModelVibeItem) => projectModelVibeItem.projectModelElementId === projectModelElement.id)!;
  return (
    <div className="panel project-model-element-panel">
      <div
        className={`panel-header${projectModelElement.isBoolean ? " is-boolean" : ""}`}
        onClick={() => {
          if (!projectModelElement.isBoolean) {
            setShow(!show);
          }
        }}
      >
        <label>{projectModelElement.name}</label>
        <div>
          {projectModelElement.isBoolean && (
            <Toggle
              readOnly={customerChoiceItems === undefined || setCustomerChoiceItems === undefined}
              checked={(customerChoiceItem?.projectModelElementOptionId ?? null) !== null}
              onChange={(checked) => {
                if (customerChoiceItems && setCustomerChoiceItems) {
                  setCustomerChoiceItems(
                    produce(customerChoiceItems, (draft) => {
                      const index = draft.findIndex((item) => item.projectModelElementId === projectModelElement.id);
                      if (index !== -1) {
                        if (checked) {
                          draft[index].projectModelElementOptionId = projectModelElement.options.find((_) => true)!.id;
                        } else {
                          draft[index].projectModelElementOptionId = null;
                        }
                      }
                    })
                  );
                }
              }}
              size="md"
            />
          )}
          {!projectModelElement.isBoolean && <>{show ? <ArrowUpLineIcon /> : <ArrowDownLineIcon />}</>}
          <ProjectModelElementPrice
            projectModel={projectModel}
            projectModelElement={projectModelElement}
            projectModelElementOption={projectModelElementOption}
            projectModelVibeId={projectModelVibeId}
            customerContract={customerContract}
          />
          {projectModelElement.isOptional && !projectModelElement.isBoolean && <Tag size="sm">Facultatif</Tag>}
        </div>
      </div>
      {!projectModelElement.isBoolean && (
        <Animation.Collapse in={show} className="panel-body">
          {(props, ref) => (
            <div {...props} ref={ref}>
              {projectModelElement.options.length > 1 &&
                projectProductCategories.map((projectProductCategory) => (
                  <div className="panel-body-content" key={projectProductCategory.id}>
                    <label>{projectProductCategory.name}</label>
                    <section>
                      {projectProducts
                        .filter((projectProduct) => projectProduct.category?.id === projectProductCategory.id)
                        .map((projectProduct) => {
                          const projectModelElementOption = projectModelElement.options.find((projectModelElementOption) => projectModelElementOption.product.id === projectProduct.id)!;
                          return (
                            <ProjectModelElementOptionPanel
                              key={projectModelElementOption.id}
                              projectModelElementOption={projectModelElementOption}
                              projectModelVibeItem={projectModelVibeItem}
                              customerContract={customerContract}
                              active={
                                customerChoiceItems !== undefined
                                  ? () => {
                                      if (projectModelElementOption) {
                                        const projectModelVibeItem = customerChoiceItems.find((customerChoiceItem) => customerChoiceItem.projectModelElementId === projectModelElement.id);
                                        if (projectModelVibeItem) {
                                          return projectModelElementOption.id === projectModelVibeItem.projectModelElementOptionId;
                                        }
                                      }
                                      return false;
                                    }
                                  : undefined
                              }
                              onClick={() => {
                                if (customerChoiceItems && setCustomerChoiceItems) {
                                  setCustomerChoiceItems(
                                    produce(customerChoiceItems, (draft) => {
                                      const index = draft.findIndex((item) => item.projectModelElementId === projectModelElement.id);
                                      if (index !== -1) {
                                        if (projectModelElementOption) {
                                          if (draft[index].projectModelElementOptionId !== projectModelElementOption.id) {
                                            draft[index].projectModelElementOptionId = projectModelElementOption.id;
                                          }
                                        }
                                      }
                                    })
                                  );
                                }
                              }}
                            />
                          );
                        })}
                    </section>
                  </div>
                ))}
            </div>
          )}
        </Animation.Collapse>
      )}
    </div>
  );
};
