import "./index.less";
import { FunctionComponent } from "react";
import { ProjectProduct } from "graphql/__generated__/ProjectProduct";
import { ProjectProductThumbnail } from "components/ProjectProductThumbnail";
import { CustomerContract } from "graphql/__generated__/CustomerContract";
import { ProjectModelVibe } from "graphql/__generated__/ProjectModelVibe";
import { ProjectModel } from "graphql/__generated__/ProjectModel";
import { ContractChoiceMode } from "graphql/globalTypes";
import { ReviewPriceByPreviousCustomerChoiceItem, ReviewPriceByProjectModelVibeItem } from "components";

type Props = {
  readonly projectModel: ProjectModel;
  readonly projectModelVibe: ProjectModelVibe;
  readonly projectProducts: ReadonlyArray<ProjectProduct>;
  readonly customerContract: CustomerContract;
  readonly value: {
    readonly projectModelElementId: string;
    readonly projectModelElementOptionId: string | null;
  };
};

export const ReviewProjectModelElementOption: FunctionComponent<Props> = ({ projectModel, projectModelVibe, projectProducts, customerContract, value }) => {
  const currentCustomerContractChoice = customerContract.choices.find((customerContractChoice) => customerContractChoice.id === customerContract.currentChoiceId)!;
  const projectModelElement = projectModel.elements.find((projectModelElement) => projectModelElement.id === value.projectModelElementId)!;
  const projectModelElementOption = projectModelElement.options.find((projectModelElementOption) => projectModelElementOption.id === value.projectModelElementOptionId);
  const projectProduct = projectProducts.find((projectProduct) => projectProduct.id === projectModelElementOption?.product.id);
  return (
    <div className="review-project-model-element-option">
      <div className="review-project-model-element-option-label">
        {!projectModelElement.isBoolean && projectProduct && <span>{projectProduct.name}</span>}
        {currentCustomerContractChoice.mode === ContractChoiceMode.PREVIOUS_CONTRACT_CHOICE_ITEMS && (
          <ReviewPriceByPreviousCustomerChoiceItem projectModel={projectModel} customerContract={customerContract} currentCustomerContractChoice={currentCustomerContractChoice} value={value} />
        )}
        {currentCustomerContractChoice.mode === ContractChoiceMode.PROJECT_MODEL_VIBE_ITEMS && (
          <ReviewPriceByProjectModelVibeItem projectModel={projectModel} projectModelVibe={projectModelVibe} currentCustomerContractChoice={currentCustomerContractChoice} value={value} />
        )}
      </div>
      {!projectModelElement.isBoolean && <ProjectProductThumbnail width={48} height={48} projectProduct={projectProduct} />}
    </div>
  );
};
