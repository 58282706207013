import "./index.less";
import { FunctionComponent } from "react";
import { ProjectProduct } from "graphql/__generated__/ProjectProduct";

type Props = {
  readonly projectProduct: ProjectProduct | null | undefined;
  readonly width: number;
  readonly height: number;
  readonly onClick?: (() => void) | undefined;
};

export const ProjectProductThumbnail: FunctionComponent<Props> = ({ projectProduct, width, height, onClick }) => {
  if (projectProduct && projectProduct.hasThumbnail) {
    return (
      <img
        onClick={onClick}
        style={{
          width,
          height,
        }}
        className="project-product-thumbnail"
        alt="Vignette du produit"
        src={`${process.env.REACT_APP_AZURE_STORAGE_BLOB_SERVICE_URL}/project-products/${projectProduct.id}/thumbnail.png`}
      />
    );
  } else {
    return (
      <div
        onClick={onClick}
        style={{
          width,
          height,
        }}
        className="project-product-thumbnail"
      />
    );
  }
};
