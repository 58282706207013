import "./index.less";
import { View } from "buildingBlocks";
import { SalesOfficeLocation } from "components";
import { FunctionComponent } from "react";
import { FaInstagram, FaLinkedinIn, FaFacebookF } from "react-icons/fa";

export const ContactUsView: FunctionComponent = () => {
  return (
    <View id="contact-us-view" title="Nous joindre">
      <section className="contact-us-info">
        <div className="contact-us-address">
          <label>ADRESSE</label>
          <address>
            1751, RUE RICHARDSON, BUREAU 4.300
            <br />
            MONTRÉAL, QUÉBEC, H3K 1G6
            <br />
            CANADA
          </address>
        </div>
        <div className="contact-us-direct">
          <div className="contact-us-phone">
            <label>TÉLÉPHONE</label>
            <a href="tel:(514) 876-9900">(514) 876-9900</a>
          </div>
          <div className="contact-us-email">
            <label>COURRIEL</label>
            <a href="mailto:info@devmeta.ca">info@devmeta.ca</a>
          </div>
        </div>
        <div className="contact-us-social-media">
          <label>MÉDIA SOCIAUX</label>
          <ul>
            <li>
              <a href={process.env.REACT_APP_LINKEDIN_URL}>
                <FaLinkedinIn />
              </a>
            </li>
            <li>
              <a href={process.env.REACT_APP_FACEBOOK_URL}>
                <FaFacebookF />
              </a>
            </li>
            <li>
              <a href={process.env.REACT_APP_INSTAGRAM_URL}>
                <FaInstagram />
              </a>
            </li>
          </ul>
        </div>
      </section>
      <hr />
      <section className="contact-us-map">
        <SalesOfficeLocation />
      </section>
    </View>
  );
};
