import "./index.less";
import { FunctionComponent } from "react";
import { Tooltip, Whisper } from "rsuite";
import { ProjectModelElementOption } from "graphql/__generated__/ProjectModelElementOption";
import { ProjectModelVibeItem } from "graphql/__generated__/ProjectModelVibeItem";
import { CustomerContract } from "graphql/__generated__/CustomerContract";
import { ProjectModelElementOptionPrice, ProjectProductThumbnail } from "components";

type Props = {
  readonly projectModelVibeItem: ProjectModelVibeItem;
  readonly projectModelElementOption: ProjectModelElementOption;
  readonly customerContract: CustomerContract;
  readonly active?: (() => boolean) | boolean | undefined;
  readonly onClick?: (() => void) | undefined;
};

export const ProjectModelElementOptionPanel: FunctionComponent<Props> = ({ projectModelElementOption, projectModelVibeItem, customerContract, active, onClick }) => {
  const projectProduct = projectModelElementOption.product;
  let className = "project-model-element-option-panel";
  if (active !== undefined) {
    if (typeof active === "function") {
      if (active()) {
        className += " active";
      }
    }
    if (typeof active === "boolean") {
      if (active) {
        className += " active";
      }
    }
  }
  return (
    <Whisper
      placement="top"
      trigger="hover"
      speaker={({ className }, ref) => {
        return (
          <Tooltip ref={ref} className={className}>
            {projectProduct.variant ?? projectProduct.name}
          </Tooltip>
        );
      }}
    >
      <div className={className}>
        <ProjectProductThumbnail width={72} height={72} projectProduct={projectProduct} onClick={onClick} />
        <ProjectModelElementOptionPrice projectModelElementOption={projectModelElementOption} projectModelVibeItem={projectModelVibeItem} customerContract={customerContract} />
      </div>
    </Whisper>
  );
};
