import "./index.less";
import { FunctionComponent, useState } from "react";
import { Form, Input, InputPicker, MaskedInput } from "rsuite";
import { Country } from "graphql/__generated__/Country";
import produce from "immer";

type ProfileFormValues = {
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly phone: string;
  readonly gender: string;
  readonly language: string;
  readonly address: {
    readonly line: string;
    readonly city: string;
    readonly state: string;
    readonly country: string;
    readonly postalCode: string;
  };
};

type Props = {
  readonly countries: Country[];
  readonly initialValues: ProfileFormValues;
};

export const ProfileForm: FunctionComponent<Props> = ({ countries, initialValues }) => {
  const [values, setValues] = useState<ProfileFormValues>(initialValues);
  const [country, setCountry] = useState<Country | undefined>(countries.find((country) => country.code === values.address.country));
  return (
    <>
      <Form id="profile-form" className="form" fluid>
        <Form.Group>
          <Form.ControlLabel>Prénom</Form.ControlLabel>
          <Input
            readOnly
            value={values.firstName}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.firstName = value;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Nom</Form.ControlLabel>
          <Input
            readOnly
            value={values.lastName}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.lastName = value;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Courriel</Form.ControlLabel>
          <Input
            readOnly
            type="email"
            value={values.email}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.email = value;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Téléphone</Form.ControlLabel>
          <MaskedInput
            readOnly
            value={values.phone}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.phone = value;
                })
              );
            }}
            type="phone"
            mask={["+", "1", " ", "(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
            placeholder="+1 (555) 555-5555"
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Genre</Form.ControlLabel>
          <InputPicker
            readOnly
            value={values.gender}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.gender = value;
                })
              );
            }}
            cleanable={false}
            block
            data={[
              {
                label: "Homme",
                value: "MALE",
              },
              {
                label: "Femme",
                value: "FEMALE",
              },
              {
                label: "Non binaire",
                value: "NON_BINARY",
              },
            ]}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Langue</Form.ControlLabel>
          <InputPicker
            readOnly
            value={values.language}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.language = value;
                })
              );
            }}
            cleanable={false}
            block
            data={[
              {
                label: "Français",
                value: "fr",
              },
              {
                label: "English",
                value: "en",
              },
            ]}
          />
        </Form.Group>
        <h2 style={{ marginBottom: "20px" }}>Adresse</h2>
        <Form.Group>
          <Form.ControlLabel>Pays</Form.ControlLabel>
          <InputPicker
            value={values.address.country}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.address.country = value;
                })
              );
              setCountry(countries.find((country) => country.code === value));
            }}
            readOnly={!!country}
            cleanable={false}
            block
            data={countries.map((country) => ({
              label: country.name,
              value: country.code,
            }))}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Rue</Form.ControlLabel>
          <Input
            readOnly
            disabled={!country}
            value={values.address.line}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.address.line = value;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Ville</Form.ControlLabel>
          <Input
            readOnly
            disabled={!country}
            value={values.address.city}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.address.city = value;
                })
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>État/Province</Form.ControlLabel>
          <InputPicker
            readOnly
            value={values.address.state}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.address.state = value;
                })
              );
            }}
            disabled={!country}
            cleanable={false}
            block
            data={
              country !== undefined
                ? country.subdivisions.map((subdivision) => ({
                    label: subdivision.name,
                    value: subdivision.code,
                  }))
                : []
            }
          />
        </Form.Group>
        <Form.Group controlId="address.postalCode">
          <Form.ControlLabel>Code postal</Form.ControlLabel>
          <Input
            readOnly
            disabled={!country}
            value={values.address.postalCode}
            onChange={(value) => {
              setValues(
                produce(values, (draft) => {
                  draft.address.postalCode = value;
                })
              );
            }}
          />
        </Form.Group>
      </Form>
    </>
  );
};
