import "./index.less";
import { FunctionComponent, useState } from "react";
import { Button, Form, InputPicker, Message, Tag } from "rsuite";
import {
  useCustomerContractChoiceExtraOptionTotalAmount,
  useProjectContext,
  useProjectModelContext,
  useProjectProductCategoryContext,
  useProjectProductContext,
  useProjectUnitContext,
  useRouting,
} from "hooks";
import { CustomerContractChoiceTotalAmount, ProjectModelElementPanelGroup, ProjectModelUnityWebGL } from "components";
import { CustomerContract } from "graphql/__generated__/CustomerContract";
import produce from "immer";
import { ContractChoiceMode } from "graphql/globalTypes";
import { FormError } from "buildingBlocks";

export type CustomerChoiceUpsertFormValues = {
  readonly projectModelVibeId: string;
  readonly items: {
    readonly projectModelElementId: string;
    readonly projectModelElementOptionId: string | null;
  }[];
};

type Props = {
  readonly customerContract: CustomerContract;
  readonly errors: string[];
  readonly loading: boolean;
  readonly onSubmit: (values: CustomerChoiceUpsertFormValues) => Promise<void>;
  readonly onCancel: () => void;
};

export const CustomerChoiceUpdateForm: FunctionComponent<Props> = ({ customerContract, errors, loading, onSubmit, onCancel }) => {
  const { navigate, toContractChoiceConfirmView } = useRouting();
  const { projectModel } = useProjectModelContext(customerContract.projectModelId);
  const { project } = useProjectContext(customerContract.projectId);
  const { projectUnit } = useProjectUnitContext(customerContract.projectUnitId);
  const { projectProducts } = useProjectProductContext(customerContract.projectModelId);
  const { projectProductCategories } = useProjectProductCategoryContext(customerContract.projectModelId);
  const [projectModelRoomId, setProjectModelRoomId] = useState<string>("");
  const { initialValues } = useInitialValues(customerContract);
  const [values, setValues] = useState<CustomerChoiceUpsertFormValues>(initialValues);
  const [showUnityWebGL, setShowUnityWebGL] = useState<boolean>(true);
  const { totalAmount } = useCustomerContractChoiceExtraOptionTotalAmount(projectModel, values.projectModelVibeId, customerContract, values.items);
  const currentCustomerContractChoice = customerContract.choices.find((customerContractChoice) => customerContractChoice.id === customerContract.currentChoiceId);
  const previousCustomerContractChoice = customerContract.choices.find((customerContractChoice) => customerContractChoice.id === customerContract.previousChoiceId);
  if (!projectModel) {
    return <></>; // TODO:
  }
  if (!currentCustomerContractChoice) {
    return <></>; // TODO:
  }
  return (
    <Form id="customer-choice-update-form" className="form" fluid>
      <div className="customer-choice-update-body">
        {showUnityWebGL ? (
          <ProjectModelUnityWebGL
            customerContractId={customerContract.id}
            projectModelRoomId={projectModelRoomId}
            customerChoiceItems={values.items.map((item) => ({
              projectModelElementId: item.projectModelElementId,
              projectModelElementOptionId: item.projectModelElementOptionId,
            }))}
          />
        ) : (
          <div className="project-model-unity-webgl" />
        )}
        <aside className="project-model-unity-webgl-menu">
          <div className="project-model-unity-webgl-menu-header">
            <div className="title">
              <span className="project-name">{project.name}</span>
              <span className="project-unit-label">{projectUnit.label}</span>
            </div>
            <Tag size="lg" className="customer-contract-choice-label">
              {currentCustomerContractChoice.label.toUpperCase()}
            </Tag>
          </div>
          <div className="project-model-unity-webgl-menu-body">
            <FormError errors={errors} />
            <div className="vibe-selector">
              <label className="vibe-selector-label">Ambiance</label>
              <InputPicker
                className="vibe-selector-input-picker"
                disabled={currentCustomerContractChoice.mode === ContractChoiceMode.PREVIOUS_CONTRACT_CHOICE_ITEMS}
                size="sm"
                cleanable={false}
                value={values.projectModelVibeId}
                onChange={(value) => {
                  setValues(
                    produce(values, (draft) => {
                      if (draft.projectModelVibeId !== value) {
                        draft.projectModelVibeId = value;
                        draft.items = projectModel.vibes
                          .find((projectModelVibe) => projectModelVibe.id === value)!
                          .items.map((projectModelVibeItem) => ({
                            projectModelElementId: projectModelVibeItem.projectModelElementId,
                            projectModelElementOptionId: projectModelVibeItem.projectModelElementOptionId,
                          }));
                      }
                    })
                  );
                }}
                data={projectModel.vibes
                  .filter((projectModelVibe) => !projectModelVibe.isDraft)
                  .map((projectModelVibe) => ({
                    label: projectModelVibe.name,
                    value: projectModelVibe.id,
                  }))}
              />
              {currentCustomerContractChoice.mode !== ContractChoiceMode.PROJECT_MODEL_VIBE && (
                <Button
                  className="vibe-selector-reset-button"
                  size="sm"
                  appearance="subtle"
                  onClick={() => {
                    setValues(
                      produce(values, (draft) => {
                        if (currentCustomerContractChoice.mode === ContractChoiceMode.PROJECT_MODEL_VIBE_ITEMS) {
                          draft.items = projectModel.vibes
                            .find((projectModelVibe) => projectModelVibe.id === values.projectModelVibeId)!
                            .items.map((projectModelVibeItem) => ({
                              projectModelElementId: projectModelVibeItem.projectModelElementId,
                              projectModelElementOptionId: projectModelVibeItem.projectModelElementOptionId,
                            }));
                        }
                        if (currentCustomerContractChoice.mode === ContractChoiceMode.PREVIOUS_CONTRACT_CHOICE_ITEMS) {
                          if (previousCustomerContractChoice) {
                            draft.items = previousCustomerContractChoice.items.map((projectModelVibeItem) => ({
                              projectModelElementId: projectModelVibeItem.projectModelElementId,
                              projectModelElementOptionId: projectModelVibeItem.projectModelElementOptionId,
                            }));
                          }
                        }
                      })
                    );
                  }}
                >
                  Réinitialiser
                </Button>
              )}
            </div>
            <hr />
            <CustomerContractChoiceTotalAmount totalAmount={totalAmount} currentCustomerContractChoice={currentCustomerContractChoice} />
            {currentCustomerContractChoice.mode !== ContractChoiceMode.PROJECT_MODEL_VIBE && (
              <>
                <hr />
                <div className="room-selector">
                  <label className="room-selector-label">Pièce</label>
                  <InputPicker
                    className="room-selector-input-picker"
                    size="sm"
                    data={(projectModel?.rooms ?? []).map((projectModelRoom) => ({
                      label: projectModelRoom.name,
                      value: projectModelRoom.id,
                    }))}
                    value={projectModelRoomId}
                    onChange={setProjectModelRoomId}
                    cleanable={false}
                  />
                </div>
                <hr />
                {projectModelRoomId ? (
                  <ProjectModelElementPanelGroup
                    customerContract={customerContract}
                    projectModel={projectModel}
                    projectProductPanelSize="sm"
                    projectModelElements={projectModel.elements.filter((projectModelElement) => projectModelElement.projectModelRoomId === projectModelRoomId)}
                    projectProducts={projectProducts}
                    projectModelVibeId={values.projectModelVibeId}
                    projectProductCategories={projectProductCategories}
                    projectModelVibeItems={values.items}
                    setProjectModelVibeItems={(projectModelVibeItems) => {
                      setValues(
                        produce(values, (draft) => {
                          for (const projectModelVibeItem of projectModelVibeItems) {
                            const index = draft.items.findIndex((item) => item.projectModelElementId === projectModelVibeItem.projectModelElementId);
                            if (index !== -1) {
                              draft.items[index].projectModelElementOptionId = projectModelVibeItem.projectModelElementOptionId;
                            }
                          }
                        })
                      );
                    }}
                  />
                ) : (
                  <Message type="info">Veuillez sélectionner une pièce pour personnaliser votre ambiance.</Message>
                )}
              </>
            )}
          </div>
          <div className="project-model-unity-webgl-menu-footer">
            <Button
              disabled={
                !projectModel.elements
                  .filter((projectModelElement) => !projectModelElement.isOptional)
                  .every((projectModelElement) => values.items.find((item) => item.projectModelElementId === projectModelElement.id)?.projectModelElementOptionId !== null)
              }
              loading={loading || !showUnityWebGL}
              size="lg"
              appearance="primary"
              onClick={async () => {
                try {
                  await onSubmit(values);
                  setShowUnityWebGL(false);
                  setTimeout(() => {
                    navigate(toContractChoiceConfirmView(customerContract.id, currentCustomerContractChoice.id));
                  }, 2000);
                } catch {
                  setShowUnityWebGL(true);
                }
              }}
            >
              Enregistrer
            </Button>
            <Button size="lg" appearance="ghost" onClick={onCancel}>
              Annuler
            </Button>
          </div>
        </aside>
      </div>
    </Form>
  );
};

const useInitialValues = (customerContract: CustomerContract) => {
  const { projectModel } = useProjectModelContext(customerContract.projectModelId);
  const currentCustomerContractChoice = customerContract.choices.find((customerContractChoice) => customerContractChoice.id === customerContract.currentChoiceId);
  if (currentCustomerContractChoice && currentCustomerContractChoice.mode === ContractChoiceMode.PREVIOUS_CONTRACT_CHOICE_ITEMS) {
    const previousCustomerContractChoice = customerContract.choices.find((customerContractChoice) => customerContractChoice.id === customerContract.previousChoiceId);
    if (previousCustomerContractChoice && currentCustomerContractChoice.items.length === 0) {
      const initialValues: CustomerChoiceUpsertFormValues = {
        projectModelVibeId: previousCustomerContractChoice.projectModelVibeId!,
        items: previousCustomerContractChoice.items.map((projectModelVibeItem) => ({
          projectModelElementId: projectModelVibeItem.projectModelElementId,
          projectModelElementOptionId: projectModelVibeItem.projectModelElementOptionId,
        })),
      };
      return {
        initialValues,
      };
    } else {
      const initialValues: CustomerChoiceUpsertFormValues = {
        projectModelVibeId: currentCustomerContractChoice.projectModelVibeId!,
        items: currentCustomerContractChoice.items.map((projectModelVibeItem) => ({
          projectModelElementId: projectModelVibeItem.projectModelElementId,
          projectModelElementOptionId: projectModelVibeItem.projectModelElementOptionId,
        })),
      };
      return {
        initialValues,
      };
    }
  } else {
    if (currentCustomerContractChoice && currentCustomerContractChoice.projectModelVibeId) {
      const initialValues: CustomerChoiceUpsertFormValues = {
        projectModelVibeId: currentCustomerContractChoice.projectModelVibeId,
        items: currentCustomerContractChoice.items.map((customerContractChoiceItem) => ({
          projectModelElementId: customerContractChoiceItem.projectModelElementId,
          projectModelElementOptionId: customerContractChoiceItem.projectModelElementOptionId,
        })),
      };
      return {
        initialValues,
      };
    } else {
      const projectModelVibe = projectModel!.vibes.filter((projectModelVibe) => !projectModelVibe.isDraft).find((_) => true)!;
      const initialValues: CustomerChoiceUpsertFormValues = {
        projectModelVibeId: projectModelVibe.id,
        items: projectModelVibe.items.map((projectModelVibeItem) => ({
          projectModelElementId: projectModelVibeItem.projectModelElementId,
          projectModelElementOptionId: projectModelVibeItem.projectModelElementOptionId,
        })),
      };
      return {
        initialValues,
      };
    }
  }
};
