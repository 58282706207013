import { FunctionComponent } from "react";
import { Message, Button } from "rsuite";
import { View } from "buildingBlocks";
import { CustomerContractInvoiceDocument } from "documents";
import { useAppContext, useCustomerContractInvoiceContext, useCustomerContractInvoiceDocumentContext, useProjectContext, useProjectUnitContext } from "hooks";

export const InvoiceView: FunctionComponent = () => {
  const { customerContracts, projectModels } = useAppContext();
  const { customerContractInvoice } = useCustomerContractInvoiceContext();
  const customerContract = customerContracts.find((customerContract) => customerContract.id === customerContractInvoice.customerContractId)!;
  const { project } = useProjectContext(customerContract.projectId);
  const { projectUnit } = useProjectUnitContext(customerContract.projectUnitId);
  const { download } = useCustomerContractInvoiceDocumentContext("customer-contract-invoice-document", customerContractInvoice.label);
  return (
    <View id="invoice-view" title="Ma facture">
      <Message header="Paiement" showIcon>
        Pour effectuer votre paiement vous devez vous présenter au bureau des ventes de Dév Méta inc.
      </Message>
      <hr />
      <Button appearance="primary" size="lg" onClick={download}>
        Télécharger
      </Button>
      <hr />
      <CustomerContractInvoiceDocument
        customerContract={customerContract}
        customerContractInvoice={customerContractInvoice}
        project={project}
        projectUnit={projectUnit}
        projectModels={projectModels}
      />
      <CustomerContractInvoiceDocument
        id="customer-contract-invoice-document"
        customerContract={customerContract}
        customerContractInvoice={customerContractInvoice}
        project={project}
        projectUnit={projectUnit}
        projectModels={projectModels}
      />
    </View>
  );
};
