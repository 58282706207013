import { gql, useQuery } from "@apollo/client";
import { COUNTRY_FRAGMENT, ME_FRAGMENT } from "./fragments";
import { Country } from "./__generated__/Country";
import { FetchCountries } from "./__generated__/FetchCountries";
import { FindMe } from "./__generated__/FindMe";
import { Me } from "./__generated__/Me";

const FIND_ME_QUERY = gql`
  ${ME_FRAGMENT}
  query FindMe {
    me {
      ...Me
    }
  }
`;

export const useFindMeQuery = (onCompleted: (me: Me) => void) => {
  return useQuery<FindMe>(FIND_ME_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      onCompleted(data.me);
    },
  });
};

const FECTH_COUNTRIES_QUERY = gql`
  ${COUNTRY_FRAGMENT}
  query FetchCountries {
    countries: countries {
      ...Country
    }
  }
`;

export const useFetchCountriesQuery = (onCompleted: (countries: ReadonlyArray<Country>) => void) => {
  return useQuery<FetchCountries>(FECTH_COUNTRIES_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      onCompleted(data.countries);
    },
  });
};
