import "./index.less";
import { FunctionComponent } from "react";
import { ProjectModelElement } from "graphql/__generated__/ProjectModelElement";
import { CustomerContract } from "graphql/__generated__/CustomerContract";
import { ProjectModelElementOption } from "graphql/__generated__/ProjectModelElementOption";
import { ContractChoiceMode } from "graphql/globalTypes";
import { ReviewPriceByPreviousCustomerChoiceItem, ReviewPriceByProjectModelVibeItem } from "components";
import { ProjectModel } from "graphql/__generated__/ProjectModel";

type Props = {
  readonly customerContract: CustomerContract;
  readonly projectModel: ProjectModel;
  readonly projectModelVibeId: string;
  readonly projectModelElement: ProjectModelElement;
  readonly projectModelElementOption: ProjectModelElementOption | null;
};
export const ProjectModelElementPrice: FunctionComponent<Props> = ({ customerContract, projectModel, projectModelElement, projectModelElementOption, projectModelVibeId }) => {
  const customerContractChoice = customerContract.choices.find((customerContractChoice) => customerContractChoice.id === customerContract.currentChoiceId)!;
  if (customerContractChoice.mode === ContractChoiceMode.PREVIOUS_CONTRACT_CHOICE_ITEMS) {
    return (
      <ReviewPriceByPreviousCustomerChoiceItem
        projectModel={projectModel}
        customerContract={customerContract}
        currentCustomerContractChoice={customerContractChoice}
        value={{
          projectModelElementId: projectModelElement.id,
          projectModelElementOptionId: projectModelElementOption?.id ?? null,
        }}
      />
    );
  }
  if (customerContractChoice.mode === ContractChoiceMode.PROJECT_MODEL_VIBE_ITEMS) {
    const projectModelVibe = projectModel.vibes.find((projectModelVibe) => projectModelVibe.id === projectModelVibeId)!;
    return (
      <ReviewPriceByProjectModelVibeItem
        projectModel={projectModel}
        projectModelVibe={projectModelVibe}
        currentCustomerContractChoice={customerContractChoice}
        value={{
          projectModelElementId: projectModelElement.id,
          projectModelElementOptionId: projectModelElementOption?.id ?? null,
        }}
      />
    );
  }
  return <></>;
};
