import { ContainerLoader } from "buildingBlocks";
import { useFetchCountriesQuery, useFindMeQuery } from "graphql/queries";
import { Country } from "graphql/__generated__/Country";
import { CustomerContract } from "graphql/__generated__/CustomerContract";
import { CustomerContractInvoice } from "graphql/__generated__/CustomerContractInvoice";
import { CustomerContractPayment } from "graphql/__generated__/CustomerContractPayment";
import { CustomerProfile } from "graphql/__generated__/CustomerProfile";
import { Project } from "graphql/__generated__/Project";
import { ProjectModel } from "graphql/__generated__/ProjectModel";
import { ProjectUnit } from "graphql/__generated__/ProjectUnit";
import { createContext, FunctionComponent, PropsWithChildren, useCallback, useState } from "react";
import { useList } from "react-use";

type AppContextValue = {
  readonly countries: Country[];
  readonly customerId: string;
  readonly customerProfile: CustomerProfile;
  readonly customerContracts: CustomerContract[];
  readonly upsertCustomerContract: (customerContract: CustomerContract) => void;
  readonly customerContractInvoices: CustomerContractInvoice[];
  readonly upsertCustomerContractInvoice: (customerContractInvoice: CustomerContractInvoice) => void;
  readonly customerContractPayments: CustomerContractPayment[];
  readonly projects: Project[];
  readonly projectUnits: ProjectUnit[];
  readonly projectModels: ProjectModel[];
};

const initialValue: AppContextValue = {
  countries: [],
  customerId: "",
  customerProfile: {
    __typename: "CustomerProfile",
    firstName: "",
    lastName: "",
    fullName: "",
    email: "",
    phone: "",
    gender: "",
    language: "",
    address: {
      __typename: "CustomerAddress",
      line: "",
      country: "",
      city: "",
      state: "",
      postalCode: "",
    },
  },
  customerContracts: [],
  upsertCustomerContract: () => {},
  customerContractInvoices: [],
  upsertCustomerContractInvoice: () => {},
  customerContractPayments: [],
  projects: [],
  projectUnits: [],
  projectModels: [],
};

export const AppContext = createContext<AppContextValue>(initialValue);

export const AppProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [countries, { set: setCountries }] = useList<Country>(initialValue.countries);
  const { loading: loadingCountries } = useFetchCountriesQuery((countries) => {
    setCountries([...countries]);
  });
  const [customerId, setCustomerId] = useState<string>(initialValue.customerId);
  const [customerProfile, setCustomerProfile] = useState<CustomerProfile>(initialValue.customerProfile);
  const [customerContracts, { set: setCustomerContracts, upsert: upsertCustomerContract }] = useList<CustomerContract>(initialValue.customerContracts);
  const upsertCustomerContractInternal = useCallback(
    (customerContract: CustomerContract) => {
      upsertCustomerContract((left, right) => left.id === right.id, customerContract);
    },
    [upsertCustomerContract]
  );
  const [customerContractInvoices, { set: setCustomerContractInvoices, upsert: upsertCustomerContractInvoice }] = useList<CustomerContractInvoice>(initialValue.customerContractInvoices);
  const upsertCustomerContractInvoiceInternal = useCallback(
    (customerContractInvoice: CustomerContractInvoice) => {
      upsertCustomerContractInvoice((left, right) => left.id === right.id, customerContractInvoice);
    },
    [upsertCustomerContractInvoice]
  );
  const [customerContractPayments, { set: setCustomerContractayments }] = useList<CustomerContractPayment>(initialValue.customerContractPayments);
  const [projects, { set: setProjects }] = useList<Project>(initialValue.projects);
  const [projectUnits, { set: setProjectUnits }] = useList<ProjectUnit>(initialValue.projectUnits);
  const [projectModels, { set: setProjectModels }] = useList<ProjectModel>(initialValue.projectModels);
  const { loading } = useFindMeQuery((me) => {
    setCustomerId(me.customerId);
    setCustomerProfile(me.customerProfile);
    setCustomerContracts([...me.customerContracts]);
    setCustomerContractInvoices([...me.customerContractInvoices]);
    setCustomerContractayments([...me.customerContractPayments]);
    setProjects([...me.projects]);
    setProjectUnits([...me.projectUnits]);
    setProjectModels([...me.projectModels]);
  });
  if (loading || loadingCountries) {
    return <ContainerLoader />;
  }
  return (
    <AppContext.Provider
      value={{
        countries,
        customerId,
        customerProfile,
        customerContracts,
        upsertCustomerContract: upsertCustomerContractInternal,
        customerContractInvoices,
        upsertCustomerContractInvoice: upsertCustomerContractInvoiceInternal,
        customerContractPayments,
        projects,
        projectUnits,
        projectModels,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
