import { TagWithPopover } from "buildingBlocks";
import { ContractInvoiceStatus } from "graphql/globalTypes";
import { FunctionComponent } from "react";

type Props = {
  readonly contractInvoiceStatus: ContractInvoiceStatus;
};

export const CustomerContractInvoiceStatusTag: FunctionComponent<Props> = ({ contractInvoiceStatus }) => {
  if (contractInvoiceStatus === ContractInvoiceStatus.NO_CHARGE) {
    return <TagWithPopover title="SANS FRAIS" content="Personnalisation sans frais." color="blue" />;
  } else if (contractInvoiceStatus === ContractInvoiceStatus.PARTIAL) {
    return <TagWithPopover title="PARTIEL" content="La facture a été partiellement acquittée ou partiellement remboursé." color="yellow" />;
  } else if (contractInvoiceStatus === ContractInvoiceStatus.PAID) {
    return <TagWithPopover title="PAYÉ" content="La facture a été acquittée en totalité." color="green" />;
  } else if (contractInvoiceStatus === ContractInvoiceStatus.REFUNDED) {
    return <TagWithPopover title="REMBOURSÉ" content="La facture a été remboursé en totalité." color="green" />;
  } else {
    return <TagWithPopover title="EN ATTENTE" content="En attente de paiement." color="yellow" />;
  }
};
