import "./index.less";
import { FunctionComponent, useState } from "react";
import { Button, ButtonToolbar, Checkbox, Form, Message } from "rsuite";
import { useCustomerContractChoiceExtraOptionTotalAmount, useProjectModelContext, useProjectProductContext, useRouting } from "hooks";
import { CustomerContractChoiceTotalAmount, ProjectModelVibeItemList } from "components";
import { FormError } from "buildingBlocks";
import { CustomerContract } from "graphql/__generated__/CustomerContract";

export type CustomerChoiceValidateFormValues = {
  readonly projectModelVibeId: string;
  readonly items: {
    readonly projectModelElementId: string;
    readonly projectModelElementOptionId: string | null;
  }[];
};

type Props = {
  readonly customerContract: CustomerContract;
  readonly errors: string[];
  readonly loading: boolean;
  readonly onSubmit: () => Promise<void>;
};

export const CustomerChoiceConfirmForm: FunctionComponent<Props> = ({ customerContract, errors, loading, onSubmit }) => {
  const { navigate, toContractChoiceUpdateView } = useRouting();
  const { projectModel } = useProjectModelContext(customerContract.projectModelId);
  const { projectProducts } = useProjectProductContext(customerContract.projectModelId);
  const { initialValues } = useInitialValues(customerContract);
  const { totalAmount } = useCustomerContractChoiceExtraOptionTotalAmount(projectModel, initialValues.projectModelVibeId, customerContract, initialValues.items);
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState<boolean>(false);
  const [customerContractChoiceValidated, setCustomerContractChoiceValidated] = useState<boolean>(false);
  const currentCustomerContractChoice = customerContract.choices.find((customerContractChoice) => customerContractChoice.id === customerContract.currentChoiceId)!;
  if (projectModel === null) {
    return <></>; // TODO:
  }
  return (
    <>
      <FormError errors={errors} />
      <Form id="customer-choice-confirm-form" className="form" fluid>
        <CustomerContractChoiceTotalAmount withTopBorder totalAmount={totalAmount} currentCustomerContractChoice={currentCustomerContractChoice} />
        <Message style={{ marginBottom: "20px" }} showIcon header="Attention" type="warning">
          <p>
            Lorsque vous aurez validé vos choix d'options, votre sélection sera définitive. Vous ne serez plus en mesure d'effectuer des modifications dans l'avenir. Assurez-vous d'être certain de vos
            choix avant de cliquer sur le bouton « Valider mon choix d'options », car <strong>cette action est IRRÉVERSIBLE</strong>.
          </p>
          <p>
            Vous pouvez communiquer avec l'un de nos gestionnaires clients si vous éprouvez des difficultés ou si vous avez des questions en composant le numéro de téléphone suivant: (514) 876-9900.
          </p>
        </Message>
        {/** TODO: Valider le message avec Cindy. **/}
        <Form.Group>
          <div>
            <Checkbox
              checked={customerContractChoiceValidated}
              onChange={(_, checked) => {
                setCustomerContractChoiceValidated(checked);
              }}
            >
              Je confirme que la liste ci-dessous est exacte et est conforme à ma sélection d'options.
            </Checkbox>
          </div>
          <div>
            <Checkbox
              checked={termsAndConditionsAccepted}
              onChange={(_, checked) => {
                setTermsAndConditionsAccepted(checked);
              }}
            >
              J'accepte les termes et les conditions.
            </Checkbox>
            {/** TODO: Ajouter les termes et conditions **/}
          </div>
        </Form.Group>
        <Form.Group>
          <ButtonToolbar style={{ marginBottom: "40px" }}>
            <Button
              style={{ width: "250px" }}
              appearance="default"
              size="lg"
              onClick={() => {
                navigate(toContractChoiceUpdateView(customerContract.id, customerContract.currentChoiceId));
              }}
            >
              Modifier
            </Button>
            <Button
              style={{ width: "250px" }}
              disabled={
                !termsAndConditionsAccepted ||
                !customerContractChoiceValidated ||
                !projectModel.elements
                  .filter((projectModelElement) => !projectModelElement.isOptional)
                  .every((projectModelElement) => initialValues.items.find((item) => item.projectModelElementId === projectModelElement.id)?.projectModelElementOptionId !== null)
              }
              loading={loading}
              size="lg"
              appearance="primary"
              onClick={() => {
                onSubmit();
              }}
            >
              Confirmer
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
      {(projectModel?.rooms ?? []).map((projectModelRoom) => (
        <section key={projectModelRoom.id}>
          <h2>{projectModelRoom.name}</h2>
          <ProjectModelVibeItemList
            projectModel={projectModel}
            projectModelElements={projectModel.elements.filter((projectModelElement) => projectModelElement.projectModelRoomId === projectModelRoom.id)}
            projectProducts={projectProducts}
            customerContract={customerContract}
            values={initialValues}
          />
        </section>
      ))}
    </>
  );
};

const useInitialValues = (customerContract: CustomerContract) => {
  const { projectModel } = useProjectModelContext(customerContract.projectModelId);
  const currentCustomerContractChoice = customerContract.choices.find((customerContractChoice) => customerContractChoice.id === customerContract.currentChoiceId);
  if (currentCustomerContractChoice && currentCustomerContractChoice.projectModelVibeId) {
    const initialValues: CustomerChoiceValidateFormValues = {
      projectModelVibeId: currentCustomerContractChoice.projectModelVibeId,
      items: currentCustomerContractChoice.items.map((customerContractChoiceItem) => ({
        projectModelElementId: customerContractChoiceItem.projectModelElementId,
        projectModelElementOptionId: customerContractChoiceItem.projectModelElementOptionId,
      })),
    };
    return {
      initialValues,
    };
  } else {
    const projectModelVibe = projectModel!.vibes.filter((projectModelVibe) => !projectModelVibe.isDraft).find((_) => true)!;
    const initialValues: CustomerChoiceValidateFormValues = {
      projectModelVibeId: projectModelVibe.id,
      items: projectModelVibe.items.map((projectModelVibeItem) => ({
        projectModelElementId: projectModelVibeItem.projectModelElementId,
        projectModelElementOptionId: projectModelVibeItem.projectModelElementOptionId,
      })),
    };
    return {
      initialValues,
    };
  }
};
