import { gql } from "@apollo/client";

export const CUSTOMER_ADDRESS_FRAGMENT = gql`
  fragment CustomerAddress on CustomerAddress {
    country
    line
    city
    state
    postalCode
  }
`;

export const CUSTOMER_PROFILE_FRAGMENT = gql`
  ${CUSTOMER_ADDRESS_FRAGMENT}
  fragment CustomerProfile on CustomerProfile {
    firstName
    lastName
    fullName
    email
    phone
    gender
    language
    address {
      ...CustomerAddress
    }
  }
`;

export const CUSTOMER_CONTRACT_PAYMENT_FRAGMENT = gql`
  fragment CustomerContractPayment on CustomerContractPayment {
    id
    createdAt
    updatedAt
    label
    number
    referenceNumber
    mode
    amount
    customerContractId
    customerContractInvoiceId
  }
`;

export const CUSTOMER_CONTRACT_INVOICE_BILLING_ADDRESS_FRAGMENT = gql`
  fragment CustomerContractInvoiceBillingAddress on CustomerContractInvoiceBillingAddress {
    line
    city
    state
    country
    postalCode
  }
`;

export const CUSTOMER_CONTRACT_INVOICE_ITEM_FRAGMENT = gql`
  fragment CustomerContractInvoiceItem on CustomerContractInvoiceItem {
    id
    createdAt
    updatedAt
    index
    name
    description
    quantity
    unitPrice
    amount
  }
`;

export const CUSTOMER_CONTRACT_INVOICE_FRAGMENT = gql`
  ${CUSTOMER_CONTRACT_INVOICE_BILLING_ADDRESS_FRAGMENT}
  ${CUSTOMER_CONTRACT_INVOICE_ITEM_FRAGMENT}
  fragment CustomerContractInvoice on CustomerContractInvoice {
    id
    createdAt
    updatedAt
    number
    label
    customerName
    status
    billingAddress {
      ...CustomerContractInvoiceBillingAddress
    }
    items {
      ...CustomerContractInvoiceItem
    }
    serviceFeeRatio
    serviceFeeAmount
    subtotal
    taxRatio
    taxAmount
    total
    balance
    customerContractId
    customerContractChoiceId
  }
`;

export const PROJECT_FRAGMENT = gql`
  fragment Project on Project {
    id
    code
    name
    description
    customOptionUnitPrice
  }
`;

export const PROJECT_PRODUCT_CATEGORY_FRAGMENT = gql`
  fragment ProjectProductCategory on ProjectProductCategory {
    id
    name
    description
  }
`;

export const PROJECT_PRODUCT_FRAGMENT = gql`
  ${PROJECT_PRODUCT_CATEGORY_FRAGMENT}
  fragment ProjectProduct on ProjectProduct {
    id
    name
    variant
    description
    hasThumbnail
    category {
      ...ProjectProductCategory
    }
  }
`;

export const PROJECT_MODEL_ELEMENT_OPTION_PRICE_FRAGMENT = gql`
  fragment ProjectModelElementOptionPrice on ProjectModelElementOptionPrice {
    id
    amount
  }
`;

export const PROJECT_MODEL_ELEMENT_OPTION_FRAGMENT = gql`
  ${PROJECT_PRODUCT_FRAGMENT}
  ${PROJECT_MODEL_ELEMENT_OPTION_PRICE_FRAGMENT}
  fragment ProjectModelElementOption on ProjectModelElementOption {
    id
    isExtra
    isAvailable
    price
    prices {
      ...ProjectModelElementOptionPrice
    }
    product {
      ...ProjectProduct
    }
  }
`;

export const PROJECT_MODEL_ELEMENT_FRAGMENT = gql`
  ${PROJECT_MODEL_ELEMENT_OPTION_FRAGMENT}
  fragment ProjectModelElement on ProjectModelElement {
    id
    name
    index
    isBoolean
    isOptional
    projectModelRoomId
    defaultOptionId
    options {
      ...ProjectModelElementOption
    }
  }
`;

export const PROJECT_MODEL_ROOM_FRAGMENT = gql`
  fragment ProjectModelRoom on ProjectModelRoom {
    id
    name
    index
  }
`;

export const PROJECT_MODEL_VIBE_ITEM_FRAGMENT = gql`
  fragment ProjectModelVibeItem on ProjectModelVibeItem {
    id
    projectModelElementId
    projectModelElementOptionId
  }
`;

export const PROJECT_MODEL_VIBE_FRAGMENT = gql`
  ${PROJECT_MODEL_VIBE_ITEM_FRAGMENT}
  fragment ProjectModelVibe on ProjectModelVibe {
    id
    name
    isDraft
    items {
      ...ProjectModelVibeItem
    }
  }
`;

export const PROJECT_MODEL_FRAGMENT = gql`
  ${PROJECT_MODEL_ELEMENT_FRAGMENT}
  ${PROJECT_MODEL_VIBE_FRAGMENT}
  ${PROJECT_MODEL_ROOM_FRAGMENT}
  fragment ProjectModel on ProjectModel {
    id
    name
    elements {
      ...ProjectModelElement
    }
    rooms {
      ...ProjectModelRoom
    }
    vibes {
      ...ProjectModelVibe
    }
  }
`;

export const PROJECT_UNIT_FRAGMENT = gql`
  fragment ProjectUnit on ProjectUnit {
    id
    label
    floor
    number
    numberOfBathrooms
    numberOfBedrooms
  }
`;

export const CUSTOMER_CONTRACT_CHOICE_ITEM_FRAGMENT = gql`
  fragment CustomerContractChoiceItem on CustomerContractChoiceItem {
    id
    projectModelElementId
    projectModelElementOptionId
    projectModelElementOptionPriceId
  }
`;

export const CUSTOMER_CONTRACT_CHOICE_BILLING_SETTINGS_FRAGMENT = gql`
  fragment CustomerContractChoiceBillingSettings on CustomerContractChoiceBillingSettings {
    customOptionUnitPrice
    additionalCosts
  }
`;

export const CUSTOMER_CONTRACT_CHOICE_FRAGMENT = gql`
  ${CUSTOMER_CONTRACT_CHOICE_ITEM_FRAGMENT}
  ${CUSTOMER_CONTRACT_CHOICE_BILLING_SETTINGS_FRAGMENT}
  fragment CustomerContractChoice on CustomerContractChoice {
    id
    createdAt
    updatedAt
    status
    index
    label
    mode
    items {
      ...CustomerContractChoiceItem
    }
    billingSettings {
      ...CustomerContractChoiceBillingSettings
    }
    isDraft
    projectModelVibeId
  }
`;

export const CUSTOMER_CONTRACT_REQUEST_FRAGMENT = gql`
  fragment CustomerContractRequest on CustomerContractRequest {
    id
    createdAt
    updatedAt
    content
    projectModelElementId
  }
`;

export const CUSTOMER_CONTRACT_FRAGMENT = gql`
  ${CUSTOMER_CONTRACT_CHOICE_FRAGMENT}
  ${CUSTOMER_CONTRACT_REQUEST_FRAGMENT}
  fragment CustomerContract on CustomerContract {
    id
    balance
    isCustomized
    isCanceled
    currentChoiceId
    previousChoiceId
    choices {
      ...CustomerContractChoice
    }
    requests {
      ...CustomerContractRequest
    }
    projectId
    projectUnitId
    projectModelId
  }
`;

export const ME_FRAGMENT = gql`
  ${CUSTOMER_PROFILE_FRAGMENT}
  ${CUSTOMER_CONTRACT_FRAGMENT}
  ${CUSTOMER_CONTRACT_INVOICE_FRAGMENT}
  ${CUSTOMER_CONTRACT_PAYMENT_FRAGMENT}
  ${PROJECT_FRAGMENT}
  ${PROJECT_UNIT_FRAGMENT}
  ${PROJECT_MODEL_FRAGMENT}
  fragment Me on Me {
    customerId
    customerProfile {
      ...CustomerProfile
    }
    customerContracts {
      ...CustomerContract
    }
    customerContractInvoices {
      ...CustomerContractInvoice
    }
    customerContractPayments {
      ...CustomerContractPayment
    }
    projects {
      ...Project
    }
    projectUnits {
      ...ProjectUnit
    }
    projectModels {
      ...ProjectModel
    }
  }
`;

export const COUNTRY_SUBDIVISION_FRAGMENT = gql`
  fragment CountrySubdivision on CountrySubdivision {
    code
    name
  }
`;

export const COUNTRY_FRAGMENT = gql`
  ${COUNTRY_SUBDIVISION_FRAGMENT}
  fragment Country on Country {
    code
    name
    subdivisions {
      ...CountrySubdivision
    }
  }
`;

export const ERROR_FRAGMENT = gql`
  fragment Error on Error {
    message
  }
`;
