import "./index.less";
import { FunctionComponent, PropsWithChildren } from "react";

type Props = PropsWithChildren & {
  readonly question: string;
};

export const FrequentlyAskedQuestionPanel: FunctionComponent<Props> = ({ question, children }) => (
  <div className="frequently-asked-question-panel">
    <div className="frequently-asked-question-panel-header">{question}</div>
    <div className="frequently-asked-question-panel-body">{children}</div>
  </div>
);
