import "./index.less";
import { FunctionComponent } from "react";
import { CustomerContractChoice } from "graphql/__generated__/CustomerContractChoice";

type Props = {
  readonly withTopBorder?: boolean;
  readonly totalAmount: number;
  readonly currentCustomerContractChoice?: CustomerContractChoice | undefined;
};

export const CustomerContractChoiceTotalAmount: FunctionComponent<Props> = ({ withTopBorder = false, totalAmount, currentCustomerContractChoice }) => (
  <>
    {withTopBorder && <hr />}
    <section className="customer-contract-choice-extra-option-total-amount">
      <span>Montant total</span>
      <span>{totalAmount.toCurrencyFormat("CAD")}</span>
    </section>
    {currentCustomerContractChoice && (
      <>
        {Number(currentCustomerContractChoice.billingSettings.customOptionUnitPrice) !== 0 && (
          <section className="customer-contract-choice-extra-option-custom-option-unit-price">
            <span>Frais unitaires par changement d'option</span>
            <span>{Number(currentCustomerContractChoice.billingSettings.customOptionUnitPrice).toCurrencyFormat("CAD")}</span>
          </section>
        )}
        {Number(currentCustomerContractChoice.billingSettings.additionalCosts) !== 0 && (
          <section className="customer-contract-choice-extra-option-additional-costs">
            <span>Frais de base pour la révision</span>
            <span>{Number(currentCustomerContractChoice.billingSettings.additionalCosts).toCurrencyFormat("CAD")}</span>
          </section>
        )}
      </>
    )}
  </>
);
