import "./index.less";
import { Fragment, FunctionComponent } from "react";
import { useRouting } from "hooks";
import { CustomerContractInvoice } from "graphql/__generated__/CustomerContractInvoice";
import { Button } from "rsuite";
import { ButtonWithPopover } from "buildingBlocks";
import { ContractInvoiceStatus } from "graphql/globalTypes";
import { CustomerContractInvoiceStatusTag } from "components";
import { CustomerContractPayment } from "graphql/__generated__/CustomerContractPayment";

type Props = {
  readonly customerContractInvoice: CustomerContractInvoice;
  readonly customerContractPayments: ReadonlyArray<CustomerContractPayment>;
};

export const CustomerContractInvoicePanel: FunctionComponent<Props> = ({ customerContractInvoice, customerContractPayments }) => {
  const { navigate, toInvoiceView } = useRouting();
  customerContractPayments = customerContractPayments.filter((customerContractPayment) => customerContractPayment.customerContractInvoiceId === customerContractInvoice.id);
  return (
    <div className="customer-invoice-panel panel">
      <div className="panel-header">
        <section className="panel-title">
          <span>
            {new Date(customerContractInvoice.createdAt).toLocaleString("fr-CA", {
              year: "numeric",
              day: "2-digit",
              month: "long",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </span>
          <h2># {customerContractInvoice.label}</h2>
        </section>
        <section className="panel-status">
          <label>Statut</label>
          <div>
            <CustomerContractInvoiceStatusTag contractInvoiceStatus={customerContractInvoice.status} />
          </div>
        </section>
        <section className="panel-total">
          <label>Total</label>
          <span>
            <strong>{Number(customerContractInvoice.total).toCurrencyFormat("CAD")}</strong>
          </span>
        </section>
        <section className="panel-balance">
          <label>Solde dû</label>
          <span>
            <strong>{Number(customerContractInvoice.balance).toCurrencyFormat("CAD")}</strong>
          </span>
        </section>
        <section className="panel-action">
          <div className="panel-buttons">
            <Button
              appearance="primary"
              size="sm"
              onClick={() => {
                navigate(toInvoiceView(customerContractInvoice.id));
              }}
            >
              Voir ma facture
            </Button>
            {(customerContractInvoice.status === ContractInvoiceStatus.PENDING || customerContractInvoice.status === ContractInvoiceStatus.PARTIAL) && (
              <ButtonWithPopover
                title="Effectuer un paiement"
                content="Les paiements en ligne ne sont pas disponibles. Veuillez vous rendre au bureau des ventes de Dév Méta pour acquitter votre paiement."
                appearance="primary"
                size="sm"
                disabled
              />
            )}
          </div>
        </section>
      </div>
      {customerContractPayments.length !== 0 && (
        <div className="panel-body">
          <h4 style={{ marginBottom: "-5px" }}>PAIEMENTS</h4>
          <hr />
          {customerContractPayments.map((customerContractPayment, index) => (
            <Fragment key={customerContractPayment.id}>
              <div className="panel-contract-payment">
                <div>
                  <span>
                    {new Date(customerContractPayment.updatedAt).toLocaleString("fr-CA", {
                      year: "numeric",
                      day: "2-digit",
                      month: "long",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                  <h3>{customerContractPayment.label}</h3>
                </div>
                <div className="balance">
                  <label>Montant:</label> <span>{Number(customerContractPayment.amount).toCurrencyFormat("CAD")}</span>
                </div>
              </div>
              {index !== customerContractPayments.length - 1 && <hr />}
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
};
