import { useAuth0, User as UserAuth0 } from "@auth0/auth0-react";
import { ContractChoiceMode } from "graphql/globalTypes";
import { CustomerContract } from "graphql/__generated__/CustomerContract";
import { CustomerContractInvoice } from "graphql/__generated__/CustomerContractInvoice";
import { ProjectModel } from "graphql/__generated__/ProjectModel";
import { AppContext } from "providers";
import { useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export * from "./useRouting";

type User = UserAuth0 & {
  userId: string;
  roles: string[];
  permissions: string[];
};

export const useAuth0Context = () => {
  const {
    user: userAuth0,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    getIdTokenClaims,
    loginWithPopup,
    loginWithRedirect,
    logout,
    buildAuthorizeUrl,
    buildLogoutUrl,
    handleRedirectCallback,
    isLoading,
    isAuthenticated,
  } = useAuth0();
  const user = { ...(userAuth0 as User) };
  if (userAuth0) {
    user.userId = user[`${process.env.REACT_APP_AUTH0_AUDIENCE}/claims/userId`];
    delete user[`${process.env.REACT_APP_AUTH0_AUDIENCE}/claims/userId`];
    user.roles = user[`${process.env.REACT_APP_AUTH0_AUDIENCE}/claims/roles`];
    delete user[`${process.env.REACT_APP_AUTH0_AUDIENCE}/claims/roles`];
    user.permissions = user[`${process.env.REACT_APP_AUTH0_AUDIENCE}/claims/permissions`];
    delete user[`${process.env.REACT_APP_AUTH0_AUDIENCE}/claims/permissions`];
  }
  const hasPermissions = useCallback(
    (permissions: string[]) => {
      return permissions.every((permission) => user.permissions.includes(permission));
    },
    [user.permissions]
  );
  const hasRoles = useCallback(
    (roles: string[]) => {
      return roles.every((role) => user.roles.includes(role));
    },
    [user.roles]
  );
  return {
    user,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    getIdTokenClaims,
    loginWithPopup,
    loginWithRedirect,
    logout,
    buildAuthorizeUrl,
    buildLogoutUrl,
    handleRedirectCallback,
    isLoading,
    isAuthenticated,
    hasRoles,
    hasPermissions,
  };
};

export const useAppContext = () => useContext(AppContext);

export const useProjectContext = (projectId: string) => {
  const { projects } = useAppContext();
  return { project: projects.find((project) => project.id === projectId)! };
};

export const useProjectContextFromCustomerContract = (customerContract: CustomerContract | null) => {
  const { projects } = useAppContext();
  return { project: projects.find((project) => project.id === customerContract?.projectId) };
};

export const useProjectUnitContext = (projectUnitId: string) => {
  const { projectUnits } = useAppContext();
  return { projectUnit: projectUnits.find((projectUnit) => projectUnit.id === projectUnitId)! };
};

export const useProjectUnitContextFromCustomerContract = (customerContract: CustomerContract | null) => {
  const { projectUnits } = useAppContext();
  return { projectUnit: projectUnits.find((projectUnit) => projectUnit.id === customerContract?.projectUnitId) };
};

export const useProjectModelVibeFromCustomerContract = (customerContract: CustomerContract | null) => {
  const { projectModels } = useAppContext();
  const projectModel = projectModels.find((projectModel) => projectModel.id === customerContract?.projectModelId);
  return {
    projectModelVibe: projectModel?.vibes.find(
      (projectModelVibe) => projectModelVibe.id === customerContract?.choices.find((customerContractChoice) => customerContractChoice.id === customerContract.currentChoiceId)?.projectModelVibeId
    ),
  };
};

export const useProjectModelContext = (projectModelId: string | null) => {
  const { projectModels } = useAppContext();
  return { projectModel: projectModels.find((projectModel) => projectModel.id === projectModelId)! };
};

export const useCustomerContractContext = () => {
  const { contractId: customerContractId } = useParams<{ readonly contractId: string }>();
  const { customerContracts } = useAppContext();
  return {
    customerContract: customerContracts.find((customerContract) => customerContract.id === customerContractId)!,
  };
};

export const useCustomerContractChoiceContext = () => {
  const { choiceId: customerContractChoiceId } = useParams<{ readonly choiceId: string }>();
  const { customerContract } = useCustomerContractContext();
  return {
    customerContractChoice: customerContract.choices.find((customerContractChoice) => customerContractChoice.id === customerContractChoiceId)!,
  };
};

export const useCustomerContractInvoiceContext = () => {
  const { invoiceId } = useParams<{ readonly invoiceId: string }>();
  const { customerContracts, customerContractInvoices } = useAppContext();
  const customerContractInvoice = customerContractInvoices.find((customerContractInvoice) => customerContractInvoice.id === invoiceId)!;
  return {
    customerContract: customerContracts.find((customerContract) => customerContract.id === customerContractInvoice.customerContractId) ?? null,
    customerContractInvoice,
  };
};

export const useCustomerContractContextFromCustomerContractInvoice = (customerContractInvoice: CustomerContractInvoice) => {
  const { customerContracts } = useAppContext();
  return {
    customerContract: customerContracts.find((customerContract) => customerContract.id === customerContractInvoice.customerContractId) ?? null,
  };
};

export const useCountryContext = (countryCode: string) => {
  const { countries } = useAppContext();
  return {
    country: countries.find((country) => country.code === countryCode)!,
  };
};

export const useCountrySubdivisionContext = (countryCode: string, subdivisionCode: string) => {
  const { country } = useCountryContext(countryCode);
  return {
    countrySubdivision: country.subdivisions.find((subdivision) => subdivision.code === subdivisionCode)!,
  };
};

export const useProjectProductContext = (projectModelId: string | null) => {
  const { projectModel } = useProjectModelContext(projectModelId);
  return {
    projectProducts: (projectModel ? projectModel.elements : [])
      .map((projectModelELement) => projectModelELement.options)
      .reduce((left, right) => left.concat(right), [])
      .map((projectModelElementOption) => projectModelElementOption.product)
      .filter((value, index, self) => self.findIndex((projectProduct) => projectProduct.id === value.id) === index),
  };
};

export const useProjectProductCategoryContext = (projectModelId: string | null) => {
  const { projectProducts } = useProjectProductContext(projectModelId);
  return {
    projectProductCategories: projectProducts
      .filter((projectProduct) => projectProduct.category !== null)
      .map((projectProduct) => projectProduct.category!)
      .filter((value, index, self) => self.findIndex((projectProductCategory) => projectProductCategory.id === value.id) === index),
  };
};

export const useCustomerContractChoiceExtraOptionTotalAmount = (
  projectModel: ProjectModel | null,
  projectModelVibeId: string,
  customerContract: CustomerContract,
  values: {
    readonly projectModelElementId: string;
    readonly projectModelElementOptionId: string | null;
  }[]
) => {
  let totalAmount = 0;
  if (projectModel) {
    const projectModelVibe = projectModel.vibes.find((projectModelVibe) => projectModelVibe.id === projectModelVibeId);
    if (projectModelVibe) {
      const currentCustomerContractChoice = customerContract.choices.find((customerContractChoice) => customerContractChoice.id === customerContract.currentChoiceId);
      if (currentCustomerContractChoice) {
        totalAmount += Number(currentCustomerContractChoice.billingSettings.additionalCosts);
        if (currentCustomerContractChoice.mode === ContractChoiceMode.PROJECT_MODEL_VIBE_ITEMS) {
          for (const value of values) {
            const projectModelElement = projectModel.elements.find((projectModelElement) => projectModelElement.id === value.projectModelElementId);
            if (projectModelElement) {
              const projectModelElementOption = projectModelElement.options.find((projectModelElementOption) => projectModelElementOption.id === value.projectModelElementOptionId);
              if (projectModelElementOption) {
                const projectModelVibeItem = projectModelVibe.items.find((projectModelVibeItem) => projectModelVibeItem.projectModelElementId === value.projectModelElementId);
                if (projectModelVibeItem && projectModelVibeItem.projectModelElementOptionId !== value.projectModelElementOptionId) {
                  if (projectModelElementOption.isExtra) {
                    totalAmount += projectModelElementOption.price ?? 0;
                  } else {
                    totalAmount += currentCustomerContractChoice.billingSettings.customOptionUnitPrice;
                  }
                }
              }
            }
          }
        }
        if (currentCustomerContractChoice.mode === ContractChoiceMode.PREVIOUS_CONTRACT_CHOICE_ITEMS) {
          const previousCustomerContractChoice = customerContract.choices.find((customerContractChoice) => customerContractChoice.id === customerContract.previousChoiceId);
          if (previousCustomerContractChoice) {
            for (const value of values) {
              const previousCustomerChoiceItem = previousCustomerContractChoice.items.find(
                (previousCustomerChoiceItem) => previousCustomerChoiceItem.projectModelElementId === value.projectModelElementId
              );
              if (previousCustomerChoiceItem) {
                if (previousCustomerChoiceItem.projectModelElementOptionId !== value.projectModelElementOptionId) {
                  totalAmount += currentCustomerContractChoice.billingSettings.customOptionUnitPrice;
                  const projectModelElement = projectModel.elements.find((projectModelElement) => projectModelElement.id === value.projectModelElementId);
                  if (projectModelElement) {
                    const previousProjectModelElementOption = projectModelElement.options.find(
                      (projectModelElementOption) => projectModelElementOption.id === previousCustomerChoiceItem.projectModelElementOptionId
                    );
                    if (previousProjectModelElementOption && previousProjectModelElementOption.isExtra) {
                      const previousProjectModelElementOptionPrice = previousProjectModelElementOption.prices.find(
                        (previousProjectModelElementOptionPrice) => previousProjectModelElementOptionPrice.id === previousCustomerChoiceItem.projectModelElementOptionPriceId
                      );
                      if (previousProjectModelElementOptionPrice) {
                        totalAmount -= previousProjectModelElementOptionPrice.amount;
                      }
                    }
                    const projectModelElementOption = projectModelElement.options.find((projectModelElementOption) => projectModelElementOption.id === value.projectModelElementOptionId);
                    if (projectModelElementOption && projectModelElementOption.isExtra) {
                      totalAmount += projectModelElementOption.price ?? 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return {
    totalAmount,
  };
};

export const useCustomerContractInvoiceDocumentContext = (elementId: string, customerContractInvoiceLabel: string) => {
  return {
    download: async () => {
      const element = document.getElementById(elementId); // TODO: useRef
      if (element) {
        const height = element.offsetHeight;
        const width = element.offsetWidth;
        const ratio = height / width;
        const canvas = await html2canvas(element, {
          height,
          width,
        });
        const image = canvas.toDataURL("image/png");
        const pdf = new jsPDF("portrait", "px", "letter");
        var pageWidth = pdf.internal.pageSize.getWidth();
        let pageHeight = pdf.internal.pageSize.getHeight();
        pageHeight = ratio * pageWidth;
        pdf.addImage(image, "PNG", 0, 0, pageWidth, pageHeight, undefined, "NONE");
        pdf.autoPrint();
        pdf.save(customerContractInvoiceLabel);
      }
    },
  };
};

export const useCustomerContractChoiceDocumentContext = (elementClassNames: string, filename: string) => {
  return {
    download: async () => {
      const pdf = new jsPDF("portrait", "px", "ledger");
      const elements = document.getElementsByClassName(elementClassNames);
      for (let index = 0; index < elements.length; index++) {
        if (index !== 0) {
          pdf.addPage("ledger", "portrait");
        }
        const element = elements[index] as HTMLElement;
        const height = element.offsetHeight;
        const width = element.offsetWidth;
        const ratio = height / width;
        const canvas = await html2canvas(element, {
          height,
          width,
        });
        const image = canvas.toDataURL("image/png");
        var pageWidth = pdf.internal.pageSize.getWidth();
        let pageHeight = pdf.internal.pageSize.getHeight();
        pageHeight = ratio * pageWidth;
        pdf.addImage(image, "PNG", 0, 0, pageWidth, pageHeight, undefined, "NONE");
      }
      pdf.autoPrint();
      pdf.save(filename);
    },
  };
};
