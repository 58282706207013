import { useAppContext } from "hooks";
import { FunctionComponent } from "react";
import { ProfileForm } from "forms";
import { View } from "buildingBlocks";

export const ProfileView: FunctionComponent = () => {
  const { countries, customerProfile } = useAppContext();
  return (
    <View title="Mon profile">
      <ProfileForm
        countries={countries}
        initialValues={{
          firstName: customerProfile.firstName,
          lastName: customerProfile.lastName,
          phone: customerProfile.phone,
          email: customerProfile.email,
          language: customerProfile.language,
          gender: customerProfile.gender,
          address: {
            line: customerProfile.address.line,
            city: customerProfile.address.city,
            state: customerProfile.address.state,
            country: customerProfile.address.country,
            postalCode: customerProfile.address.postalCode,
          },
        }}
      />
    </View>
  );
};
