import "./index.less";
import { FunctionComponent } from "react";
import { Message } from "rsuite";
import { ProjectModel } from "graphql/__generated__/ProjectModel";
import { CustomerContract } from "graphql/__generated__/CustomerContract";
import { CustomerContractChoice } from "graphql/__generated__/CustomerContractChoice";
import { CustomerContractInfos } from "components";
import { Project } from "graphql/__generated__/Project";
import { ProjectUnit } from "graphql/__generated__/ProjectUnit";

type Props = {
  readonly pageClassName?: string;
  readonly projectModel: ProjectModel | null | undefined;
  readonly customerContract: CustomerContract | null | undefined;
  readonly customerContractChoice: CustomerContractChoice | null | undefined;
  readonly project: Project;
  readonly projectModels: ReadonlyArray<ProjectModel>;
  readonly projectUnits: ReadonlyArray<ProjectUnit>;
};

export const CustomerReportByUnitNumberDocument: FunctionComponent<Props> = ({
  pageClassName = undefined,
  project,
  projectModels,
  projectUnits,
  customerContract,
  customerContractChoice,
  projectModel,
}) => {
  const style: React.CSSProperties = {};
  if (pageClassName) {
    style.display = "none";
  }
  if (!customerContract) {
    return (
      <Message type="warning" style={style} header={<h2>Unité sans contrat</h2>}>
        Un contrat client doit être associé à cette unité pour qu'un rapport soit généré.
      </Message>
    );
  }
  if (!projectModel) {
    return (
      <Message type="warning" style={style} header={<h2>Unité sans modèle</h2>}>
        Assigner un modèle à l'unité pour que le client puisse faire son choix.
      </Message>
    );
  }
  if (customerContract.isCustomized) {
    return (
      <Message style={style} header={<h2>Unité en personnalisation</h2>}>
        Le rapport n'est pas disponible. Cette unité n'est pas gérée par l'application.
      </Message>
    );
  }
  if (customerContract.choices.length > 1 && (!customerContractChoice || customerContractChoice.items.length === 0)) {
    return (
      <Message type="warning" style={style} header={<h2>Unité en révision</h2>}>
        Le client doit refaire son choix d'options pour que sa fiche client soit générée.
      </Message>
    );
  }
  if (!customerContractChoice || customerContractChoice.items.length === 0) {
    return (
      <Message type="warning" style={style} header={<h2>Unité sans choix du client</h2>}>
        Le client doit faire son choix d'options pour qu'un rapport soit généré.
      </Message>
    );
  }
  return (
    <Document
      pageClassName={pageClassName}
      project={project}
      projectModels={projectModels}
      projectUnits={projectUnits}
      customerContract={customerContract}
      customerContractChoice={customerContractChoice}
      projectModel={projectModel}
    />
  );
};

type DocumentProps = {
  readonly pageClassName?: string;
  readonly customerContract: CustomerContract;
  readonly customerContractChoice: CustomerContractChoice;
  readonly project: Project;
  readonly projectModels: ReadonlyArray<ProjectModel>;
  readonly projectUnits: ReadonlyArray<ProjectUnit>;
  readonly projectModel: ProjectModel;
};

const Document: FunctionComponent<DocumentProps> = ({ pageClassName = undefined, project, projectModels, projectUnits, projectModel, customerContract, customerContractChoice }) => {
  const { withoutExtraTables, withExtraTables } = useCustomerReportByUnitNumberContext(customerContract, customerContractChoice, projectModel);
  const style: React.CSSProperties = {};
  if (pageClassName) {
    style.position = "absolute";
    style.right = "-2000px";
    style.bottom = "-2000px";
    style.width = "1056px";
    style.padding = "50px";
  }
  return (
    <div style={style} className="customer-report-by-unit-number-document">
      {customerContract && (
        <CustomerContractInfos
          title="FICHE CLIENT"
          className={pageClassName}
          customerContract={customerContract}
          customerContractChoiceId={customerContractChoice.id}
          project={project}
          projectModels={projectModels}
          projectUnits={projectUnits}
        />
      )}
      <Page className={pageClassName} tables={withoutExtraTables} isExtra={false} />
      <Page className={pageClassName} tables={withExtraTables} isExtra={true} />
    </div>
  );
};

type PageProps = {
  readonly tables: CustomerReportByUnitNumberTable[];
  readonly className?: string;
  readonly isExtra: boolean;
};

const Page: FunctionComponent<PageProps> = ({ className = undefined, tables, isExtra }) => {
  return (
    <section className={`customer-report-by-unit-number${className ? ` ${className}` : ""}`}>
      <div className={isExtra ? "with-extra" : "without-extra"}>
        {tables.length !== 0 && (
          <div className="title">{isExtra ? "Ajout ou modification au choix de base (EXTRA)".toLocaleUpperCase() : "Choix de base (Standard au projet)".toLocaleUpperCase()}</div>
        )}
        {tables.map((table) => (
          <div className="table" key={Math.random()}>
            <div className="table-title">{table.roomName.toUpperCase()}</div>
            <div className="table-rows">
              <div className="table-row" key={Math.random()}>
                <div className="table-column">Élément</div>
                <div className="table-column">Choix</div>
                <div className="table-column">Commentaires</div>
              </div>
              {table.rows.map((row) => (
                <div className={`table-row${row.isUpdated ? " table-row-updated" : ""}`} key={Math.random()}>
                  <div className="table-column">{row.projectModelElementName}</div>
                  <div className="table-column">{row.projectProductName}</div>
                  <div className="table-column">{row.customerRequestContent}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

type CustomerReportByUnitNumberTable = {
  readonly roomName: string;
  readonly rows: {
    readonly projectModelElementName: string;
    readonly projectProductName: string;
    readonly customerRequestContent: string;
    readonly isUpdated: boolean;
  }[];
};

const useCustomerReportByUnitNumberContext = (projectContract: CustomerContract, projectContractChoice: CustomerContractChoice, projectModel: ProjectModel) => {
  const previousProjectContractChoice = projectContract.choices.find((contractChoice) => contractChoice.index === projectContractChoice.index - 1);
  const withoutExtraTables: CustomerReportByUnitNumberTable[] = [];
  const withExtraTables: CustomerReportByUnitNumberTable[] = [];
  const projectModelRooms: { label: string; value: string | null }[] = projectModel.rooms.map((projectModelRoom) => ({ label: projectModelRoom.name, value: projectModelRoom.id }));
  projectModelRooms.push({ label: "Autres", value: null });
  for (const projectModelRoom of projectModelRooms) {
    const withoutExtraTableRows: CustomerReportByUnitNumberTable["rows"] = [];
    const withExtraTableRows: CustomerReportByUnitNumberTable["rows"] = [];
    const projectModelElements = projectModel.elements.filter((projectModelElement) => projectModelElement.projectModelRoomId === projectModelRoom.value);
    const projectModelElementIds = projectModelElements.map((projectModelElement) => projectModelElement.id);
    const projectContractChoiceItems = projectContractChoice.items
      .filter((projectContractChoiceItem) => projectModelElementIds.includes(projectContractChoiceItem.projectModelElementId))
      .map((projectContractChoiceItem) => {
        const projectModelElement = projectModel.elements.find((projectModelElement) => projectModelElement.id === projectContractChoiceItem.projectModelElementId)!;
        const projectModelElementOption =
          projectModelElement.options.find((projectModelElementOption) => projectModelElementOption.id === projectContractChoiceItem.projectModelElementOptionId) ?? null;
        const projectModelElementOptionProduct = projectModelElementOption?.product ?? null;
        return {
          projectModelElement,
          projectModelElementOption,
          projectModelElementOptionProduct,
        };
      });
    for (const projectModelElement of projectModelElements.filter((projectModelElement) => !projectModelElement.options.every((projectModelElementOption) => projectModelElementOption.isExtra))) {
      const projectContractChoiceItem = projectContractChoiceItems.find((projectContractChoiceItem) => projectContractChoiceItem.projectModelElement.id === projectModelElement.id);
      if (projectContractChoiceItem) {
        const projectContractRequest = projectContract.requests.find((projectContractRequest) => projectContractRequest.projectModelElementId === projectModelElement.id);
        withoutExtraTableRows.push({
          projectModelElementName: projectContractChoiceItem.projectModelElement.name,
          projectProductName:
            projectContractChoiceItem.projectModelElementOption?.isExtra ?? false
              ? "EXTRA"
              : projectContractChoiceItem.projectModelElement.isBoolean && projectContractChoiceItem.projectModelElementOption !== null
              ? "OUI"
              : projectContractChoiceItem.projectModelElementOptionProduct?.name ?? "--",
          customerRequestContent: projectContractRequest?.content ?? "",
          isUpdated:
            !!previousProjectContractChoice &&
            previousProjectContractChoice.items.find(
              (previousProjectContractChoiceItem) => previousProjectContractChoiceItem.projectModelElementId === projectContractChoiceItem.projectModelElement.id
            )!.projectModelElementOptionId !== (projectContractChoiceItem.projectModelElementOption?.id ?? null),
        });
      }
    }
    for (const projectModelElement of projectModelElements.filter((projectModelElement) => projectModelElement.options.some((projectModelElementOption) => projectModelElementOption.isExtra))) {
      const projectContractChoiceItem = projectContractChoiceItems.find((projectContractChoiceItem) => projectContractChoiceItem.projectModelElement.id === projectModelElement.id);
      if (projectContractChoiceItem) {
        const projectModelElementOption = projectContractChoiceItem.projectModelElementOption;
        if (projectModelElementOption?.isExtra ?? true) {
          const projectContractRequest = projectContract.requests.find((projectContractRequest) => projectContractRequest.projectModelElementId === projectModelElement.id);
          withExtraTableRows.push({
            projectModelElementName: projectContractChoiceItem.projectModelElement.name,
            projectProductName:
              projectContractChoiceItem.projectModelElement.isBoolean && projectContractChoiceItem.projectModelElementOption !== null
                ? "OUI"
                : projectContractChoiceItem.projectModelElementOptionProduct?.name ?? "--",
            customerRequestContent: projectContractRequest?.content ?? "",
            isUpdated:
              !!previousProjectContractChoice &&
              previousProjectContractChoice.items.find(
                (previousProjectContractChoiceItem) => previousProjectContractChoiceItem.projectModelElementId === projectContractChoiceItem.projectModelElement.id
              )!.projectModelElementOptionId !== (projectContractChoiceItem.projectModelElementOption?.id ?? null),
          });
        }
      }
    }
    if (withoutExtraTableRows.length !== 0) {
      withoutExtraTables.push({
        roomName: projectModelRoom.label,
        rows: withoutExtraTableRows,
      });
    }
    if (withExtraTableRows.length !== 0) {
      withExtraTables.push({
        roomName: projectModelRoom.label,
        rows: withExtraTableRows,
      });
    }
  }
  return { withoutExtraTables, withExtraTables };
};
