import "./index.less";
import { FunctionComponent } from "react";
import { ProjectModelElement } from "graphql/__generated__/ProjectModelElement";
import { ProjectProduct } from "graphql/__generated__/ProjectProduct";
import { ProjectProductCategory } from "graphql/__generated__/ProjectProductCategory";
import { ProjectModelElementPanel } from "components";
import { CustomerChoiceItem } from "types";
import { ProjectModel } from "graphql/__generated__/ProjectModel";
import { CustomerContract } from "graphql/__generated__/CustomerContract";

type Props = {
  readonly customerContract: CustomerContract;
  readonly projectModelVibeItems?: CustomerChoiceItem[] | undefined;
  readonly setProjectModelVibeItems?: ((projectModelVibeItems: CustomerChoiceItem[]) => void) | undefined;
  readonly projectProducts: ProjectProduct[];
  readonly projectModel: ProjectModel;
  readonly projectModelElements: ProjectModelElement[];
  readonly projectProductCategories: ProjectProductCategory[];
  readonly projectProductPanelSize?: "sm" | "lg";
  readonly projectModelVibeId: string;
};

export const ProjectModelElementPanelGroup: FunctionComponent<Props> = ({
  customerContract,
  projectProducts,
  projectModelElements,
  projectProductCategories,
  projectModelVibeItems,
  projectModel,
  setProjectModelVibeItems,
  projectModelVibeId,
}) => (
  <section className="panel-group project-model-element-panel-group">
    {projectModelElements.map((projectModelElement) => {
      const products = projectModelElement.options.map((option) => projectProducts.find((projectProduct) => projectProduct.id === option.product.id)!);
      const productCategories = products
        .map((product) => projectProductCategories.find((projectProductCategory) => projectProductCategory.id === product?.category?.id)!)
        .filter((product) => product !== undefined)
        .filter((value, index, self) => self.indexOf(value) === index);
      return (
        <ProjectModelElementPanel
          key={projectModelElement.id}
          customerContract={customerContract}
          projectModel={projectModel}
          projectModelVibeId={projectModelVibeId}
          projectModelElement={projectModelElement}
          projectProducts={products}
          projectProductCategories={productCategories}
          customerChoiceItems={projectModelVibeItems}
          setCustomerChoiceItems={setProjectModelVibeItems}
        />
      );
    })}
  </section>
);
