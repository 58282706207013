import "./index.less";
import { FunctionComponent } from "react";
import { FlexboxGrid, List } from "rsuite";
import { ProjectModelElement } from "graphql/__generated__/ProjectModelElement";
import { ProjectProduct } from "graphql/__generated__/ProjectProduct";
import { CustomerContract } from "graphql/__generated__/CustomerContract";
import { ProjectModel } from "graphql/__generated__/ProjectModel";
import { ReviewProjectModelElementOption } from "components";
import { CustomerChoiceValidateFormValues } from "forms";

type Props = {
  readonly values: CustomerChoiceValidateFormValues;
  readonly customerContract: CustomerContract;
  readonly projectModel: ProjectModel;
  readonly projectProducts: ReadonlyArray<ProjectProduct>;
  readonly projectModelElements: ReadonlyArray<ProjectModelElement>;
};

export const ProjectModelVibeItemList: FunctionComponent<Props> = ({ projectProducts, projectModel, projectModelElements, values, customerContract }) => {
  const projectModelVibe = projectModel.vibes.find((projectModelVibe) => projectModelVibe.id === values.projectModelVibeId)!;
  return (
    <List className="project-model-vibe-item-list">
      {projectModelElements.map((projectModelElement, projectModelElementIndex) => {
        const value = values.items.find((value) => value.projectModelElementId === projectModelElement.id)!;
        return (
          <List.Item key={projectModelElement.id} index={projectModelElementIndex + 1}>
            <FlexboxGrid>
              <FlexboxGrid.Item colspan={12}>
                <h3>{projectModelElement.name}</h3>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item className="project-model-vibe-item-option" colspan={12}>
                <ReviewProjectModelElementOption projectModel={projectModel} projectProducts={projectProducts} projectModelVibe={projectModelVibe} customerContract={customerContract} value={value} />
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </List.Item>
        );
      })}
    </List>
  );
};
