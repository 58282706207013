import "./index.less";
import { FunctionComponent } from "react";
import { Popover, Tag, Whisper } from "rsuite";
import { TypeAttributes } from "rsuite/esm/@types/common";

type Props = {
  readonly title: string;
  readonly content: string;
  readonly color?: TypeAttributes.Color | undefined;
};

export const TagWithPopover: FunctionComponent<Props> = ({ title, content, color }) => {
  return (
    <Whisper
      trigger="hover"
      placement="top"
      controlId={`control-id-top`}
      speaker={
        <Popover title={title} visible>
          <p>{content}</p>
        </Popover>
      }
    >
      <Tag className="tag-with-popover" size="md" color={color}>
        {title}
      </Tag>
    </Whisper>
  );
};
