import "./index.less";
import { FunctionComponent, PropsWithChildren } from "react";
import { ContainerLoader } from "buildingBlocks";
import { Breadcrumb, Container, Content, Header } from "rsuite";
import AngleRightIcon from "@rsuite/icons/legacy/AngleRight";
import { useRouting } from "hooks";

type Props = PropsWithChildren & {
  readonly id?: string;
  readonly title?: string;
  readonly loading?: boolean;
  readonly fluid?: boolean;
  readonly breadcrumbItems?: {
    readonly label: string;
    readonly href?: string;
  }[];
};

export const View: FunctionComponent<Props> = ({ id, title = undefined, loading = false, breadcrumbItems = [], children, fluid = false }) => {
  const { navigate } = useRouting();
  if (loading) {
    return <ContainerLoader />;
  }
  return (
    <Container id={id} className={`view${fluid ? " view-fluid" : ""}`}>
      {(breadcrumbItems.length !== 0 || title) && (
        <Header className="view-header">
          {breadcrumbItems.length !== 0 && (
            <Breadcrumb maxItems={10} separator={<AngleRightIcon />}>
              {breadcrumbItems.map((breadcrumbItem, index) => (
                <Breadcrumb.Item
                  key={index}
                  active={!breadcrumbItem.href}
                  onClick={() => {
                    if (breadcrumbItem.href) {
                      navigate(breadcrumbItem.href);
                    }
                  }}
                >
                  {breadcrumbItem.label}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
          )}
          {title && <h1>{title.toLocaleUpperCase()}</h1>}
        </Header>
      )}
      <Content className="view-content">{children}</Content>
    </Container>
  );
};
