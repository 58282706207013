import { View } from "buildingBlocks";
import { CustomerContractPanel } from "components";
import { useAppContext } from "hooks";
import { FunctionComponent } from "react";
import { Message } from "rsuite";

export const ContractsView: FunctionComponent = () => {
  const { customerContracts } = useAppContext();
  return (
    <View id="contracts-view" title="Mes unités">
      <Message style={{ marginBottom: "20px" }} showIcon header="Ma liste d'unités" type="info">
        <p>Voici la liste de vos unités pour lesquels vous devez faire votre choix d'options. Ce processus est réalisé en 3 étapes:</p>
        <ul>
          <li>
            <strong>Mes choix:</strong> Vous devez faire votre choix d'ambiance et la personnalisation de votre unité;
          </li>
          <li>
            <strong>Confirmation:</strong> Vous devez confirmer votre choix d'ambiance et la personnalisation de votre unité;
          </li>
          <li>
            <strong>Paiement:</strong> Vous devez faire le paiement en lien avec la personnalisation de votre unité (si applicable).
          </li>
        </ul>
        <p>
          À noter, si votre unité est <strong>En personnalisation</strong> vous devez contacter votre gestionnaire client pour faire vos choix.
        </p>
      </Message>
      {customerContracts.map((customerContract) => (
        <CustomerContractPanel key={customerContract.id} customerContract={customerContract} showList />
      ))}
    </View>
  );
};
