import "./index.less";
import { FunctionComponent } from "react";
import { ContractChoiceMode } from "graphql/globalTypes";
import { CustomerContract } from "graphql/__generated__/CustomerContract";
import { ProjectModelElementOption } from "graphql/__generated__/ProjectModelElementOption";
import { ProjectModelVibeItem } from "graphql/__generated__/ProjectModelVibeItem";

type Props = {
  readonly customerContract: CustomerContract;
  readonly projectModelVibeItem: ProjectModelVibeItem;
  readonly projectModelElementOption: ProjectModelElementOption;
};

export const ProjectModelElementOptionPrice: FunctionComponent<Props> = ({ customerContract, projectModelVibeItem, projectModelElementOption }) => {
  const customerContractChoice = customerContract.choices.find((customerContractChoice) => customerContractChoice.id === customerContract.currentChoiceId)!;
  if (customerContractChoice.mode === ContractChoiceMode.PREVIOUS_CONTRACT_CHOICE_ITEMS) {
    const previousCustomerContractChoice = customerContract.choices.find((customerContractChoice) => customerContractChoice.id === customerContract.previousChoiceId)!;
    const previousCustomerContractChoiceItem = previousCustomerContractChoice.items.find(
      (previousCustomerContractChoiceItem) => previousCustomerContractChoiceItem.projectModelElementId === projectModelVibeItem.projectModelElementId
    )!;
    return (
      <div className="project-model-element-option-price">
        {projectModelElementOption && projectModelElementOption.isExtra && projectModelElementOption.price
          ? Number(projectModelElementOption.price).toCurrencyFormat("CAD")
          : previousCustomerContractChoiceItem.projectModelElementOptionId === projectModelElementOption?.id
          ? "PRÉRÉVISION"
          : Number(customerContractChoice.billingSettings.customOptionUnitPrice).toCurrencyFormat("CAD")}
      </div>
    );
  }
  return (
    <div className="project-model-element-option-price">
      {projectModelElementOption && projectModelElementOption.isExtra && projectModelElementOption.price
        ? Number(projectModelElementOption.price).toCurrencyFormat("CAD")
        : projectModelVibeItem.projectModelElementOptionId === projectModelElementOption?.id
        ? "INCLUT"
        : Number(customerContractChoice.billingSettings.customOptionUnitPrice).toCurrencyFormat("CAD")}
    </div>
  );
};
