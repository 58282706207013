import "./index.less";
import { CSSProperties, FunctionComponent } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Container, Sidebar, Sidenav, Nav, Divider, Header, Navbar } from "rsuite";
import { useAuth0 } from "@auth0/auth0-react";

const headerStyles: CSSProperties = {
  padding: 18,
  fontSize: 16,
  height: 56,
  background: "#0f283d",
  color: "#fff",
  whiteSpace: "nowrap",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
};

export const Layout: FunctionComponent = () => {
  const navigate = useNavigate();
  const { logout } = useAuth0();
  return (
    <Container>
      <Sidebar style={{ display: "flex", flexDirection: "column" }} width={260} collapsible>
        <Sidenav.Header style={headerStyles}>Dév Méta</Sidenav.Header>
        <Sidenav expanded defaultOpenKeys={["1"]} appearance="default" style={{ flex: "1 1 auto" }}>
          <Sidenav.Body>
            <Nav>
              <Nav.Item
                eventKey="1"
                onClick={() => {
                  navigate("profile");
                }}
              >
                Mon profile
              </Nav.Item>
              <Nav.Item
                eventKey="2"
                onClick={() => {
                  navigate("contracts");
                }}
              >
                Mes unités
              </Nav.Item>
              <Nav.Item
                eventKey="3"
                onClick={() => {
                  navigate("invoices");
                }}
              >
                Mes factures
              </Nav.Item>
            </Nav>
            <Divider />
            <Nav>
              <Nav.Item
                eventKey="4"
                onClick={() => {
                  navigate("contact-us");
                }}
              >
                Nous joindre
              </Nav.Item>
              <Nav.Item
                eventKey="5"
                onClick={() => {
                  navigate("frequently-asked-questions");
                }}
              >
                FAQ
              </Nav.Item>
            </Nav>
            <Divider />
            <Nav>
              <Nav.Item
                eventKey="6"
                onClick={() => {
                  logout();
                }}
              >
                Se déconnecter
              </Nav.Item>
            </Nav>
          </Sidenav.Body>
        </Sidenav>
      </Sidebar>
      <Container>
        <Header>
          <Navbar style={{ height: "56px" }} appearance="inverse" />
        </Header>
        <Outlet />
      </Container>
    </Container>
  );
};
