import { FunctionComponent } from "react";
import { useRoutes } from "react-router-dom";
import { Layout } from "../../views/_layout";
import { ProfileView } from "../../views/profile";
import { NotFound } from "../NotFound";
import { ContractsView } from "../../views/contracts";
import { InvoicesView } from "../../views/invoices";
import { ContactUsView } from "../../views/contact-us";
import { FrequentlyAskedQuestionsView } from "../../views/frequently-asked-questions";
import { LocaleProvider } from "providers";
import { InvoiceView } from "views/invoices/invoiceId";
import { ContractChoiceView } from "views/contracts/contractId/choices/choiceId";
import { ContractChoicesView } from "views/contracts/contractId/choices";
import { ContractChoiceUpdateView } from "views/contracts/contractId/choices/choiceId/update";
import { ContractChoiceConfirmView } from "views/contracts/contractId/choices/choiceId/confirm";

export const App: FunctionComponent = () => {
  const element = useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: [
        { index: true, element: <ContractsView /> },
        { path: "profile", element: <ProfileView /> },
        { path: "contact-us", element: <ContactUsView /> },
        { path: "frequently-asked-questions", element: <FrequentlyAskedQuestionsView /> },
        { path: "contracts/:contractId/choices/:choiceId/update", element: <ContractChoiceUpdateView /> },
        { path: "contracts/:contractId/choices/:choiceId/confirm", element: <ContractChoiceConfirmView /> },
        { path: "contracts/:contractId/choices/:choiceId", element: <ContractChoiceView /> },
        { path: "contracts/:contractId/choices", element: <ContractChoicesView /> },
        { path: "contracts", element: <ContractsView /> },
        { path: "invoices/:invoiceId", element: <InvoiceView /> },
        { path: "invoices", element: <InvoicesView /> },
      ],
    },
    { path: "*", element: <NotFound /> },
  ]);
  return <LocaleProvider>{element}</LocaleProvider>;
};
