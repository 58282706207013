import "./index.less";
import { View } from "buildingBlocks";
import { FrequentlyAskedQuestionPanel } from "components";
import { FunctionComponent } from "react";

export const FrequentlyAskedQuestionsView: FunctionComponent = () => {
  return (
    <View id="frequently-asked-questions-view" title="Foire aux questions">
      <FrequentlyAskedQuestionPanel question="Est-ce que les modèles 3D sont une représentation exacte de mon unité?">
        <p>
          <strong>{"Non, les modèles 3D ne sont pas une représentation réelle de votre unité.".toUpperCase()}</strong> Les modèles 3D sont une représentation d'une unité modèle qui possède les mêmes
          choix personnalisables que votre unité réelle. Vous devez utiliser les modèles uniquement comme un outil représentant vos choix d'options.
        </p>
      </FrequentlyAskedQuestionPanel>
      <FrequentlyAskedQuestionPanel question="Qu'est-ce qu'une ambiance?">
        <p>
          Une ambiance est un ensemble d'options qui ont été sélectionnées au préalable par notre équipe de designer. Vous devez choisir l'une de ces ambiances afin d'obtenir un ensemble d'options
          prédéfinies.
        </p>
        <p>
          Parfois, il vous sera proposé de modifier les choix de base de votre ambiance. À noter que des frais de gestion seront applicables pour chaque changement et que les options identifiées comme
          EXTRA seront facturées au prix indiqué.
        </p>
      </FrequentlyAskedQuestionPanel>
    </View>
  );
};
