import "./index.less";
import React, { CSSProperties } from "react";
import { CustomerContractInvoice } from "graphql/__generated__/CustomerContractInvoice";
import { useCountryContext, useCountrySubdivisionContext } from "hooks";
import { Project } from "graphql/__generated__/Project";
import { ProjectUnit } from "graphql/__generated__/ProjectUnit";
import { CustomerContract } from "graphql/__generated__/CustomerContract";
import { ProjectModel } from "graphql/__generated__/ProjectModel";

type Props = {
  readonly id?: string;
  readonly customerContract: CustomerContract;
  readonly customerContractInvoice: CustomerContractInvoice;
  readonly project: Project;
  readonly projectModels: ReadonlyArray<ProjectModel>;
  readonly projectUnit: ProjectUnit;
};

export const CustomerContractInvoiceDocument = React.forwardRef<any, Props>(({ id = undefined, project, projectUnit, projectModels, customerContract, customerContractInvoice }, ref) => {
  const { country } = useCountryContext(customerContractInvoice.billingAddress.country);
  const { countrySubdivision } = useCountrySubdivisionContext(customerContractInvoice.billingAddress.country, customerContractInvoice.billingAddress.state);
  const customerContractChoice = customerContract.choices.find((customerContractChoice) => customerContractChoice.id === customerContractInvoice.customerContractChoiceId);
  const projectModel = projectModels.find((projectModel) => projectModel.id === customerContract.projectModelId);
  const projectModelVibe = projectModel?.vibes.find((projectModelVibe) => projectModelVibe.id === customerContractChoice?.projectModelVibeId);
  const style: CSSProperties = {};
  if (id) {
    style.position = "absolute";
    style.right = "-2000px";
    style.bottom = "-2000px";
    style.width = "816px";
    style.fontSize = "75%";
    style.color = "black";
    style.padding = "50px";
  }
  return (
    <div id={id} className="invoice-page" style={style} ref={ref}>
      <div className="invoice-header">
        <div className="invoice-header-left">
          <div className="invoice-address">
            <strong>Dév Méta inc.</strong>
            <address>
              1751, RUE RICHARDSON, BUREAU 4.300
              <br />
              MONTREAL, QUÉBEC, H3K 1G6
              <br />
              CANADA
            </address>
          </div>
          <div className="invoice-unit-info">
            <div>
              <label>Nom du projet :</label>
              <span>{project.name}</span>
            </div>
            <div>
              <label>Unité :</label>
              <span>#{projectUnit.number}</span>
            </div>
            {projectModel && (
              <div>
                <label>Modèle :</label>
                <span>{projectModel.name}</span>
              </div>
            )}
            {projectModelVibe && (
              <div>
                <label>Ambiance :</label>
                <span>{projectModelVibe.name}</span>
              </div>
            )}
          </div>
          {/** TODO: Créer un concept de compagnie. */}
        </div>
        <div className="invoice-header-right">
          <div className="invoice-title">
            <h2>FACTURE</h2>
            <span># {customerContractInvoice.label}</span>
          </div>
          <div className="invoice-balance">
            <label>Solde dû</label>
            <span>
              <strong>{Number(customerContractInvoice.balance).toCurrencyFormat("CAD")}</strong>
            </span>
          </div>
        </div>
      </div>
      <div className="invoice-body">
        <div className="invoice-body-top">
          <div className="invoice-billing-address">
            <label>Facturer à</label>
            <address>
              <strong>{customerContractInvoice.customerName}</strong>
              <br />
              {customerContractInvoice.billingAddress.line}
              <br />
              {customerContractInvoice.billingAddress.city}, {countrySubdivision.name.toUpperCase()}, {customerContractInvoice.billingAddress.postalCode}
              <br />
              {country.name.toUpperCase()}
            </address>
          </div>
          <div className="invoice-conditions">
            <div>
              <label>Date de facture :</label>
              <span>{new Date(customerContractInvoice.createdAt).toLocaleDateString("fr-CA")}</span>
            </div>
          </div>
        </div>
        <div className="invoice-items-table">
          <div className="table-head">
            <div className="table-row">
              <div className="table-column table-column-index">#</div>
              <div className="table-column table-column-description">Description</div>
              <div className="table-column table-column-quantity">Quantité</div>
              <div className="table-column table-column-unit-price">Prix unitaire</div>
              <div className="table-column table-column-amount">Montant</div>
            </div>
          </div>
          <div className="table-body">
            {customerContractInvoice.items.map((customerContractInvoiceItem) => (
              <div key={customerContractInvoiceItem.id} className="table-row">
                <div className="table-column table-column-index">{customerContractInvoiceItem.index + 1}</div>
                <div className="table-column table-column-description">{customerContractInvoiceItem.name}</div>
                <div className="table-column table-column-quantity">{customerContractInvoiceItem.quantity}</div>
                <div className="table-column table-column-unit-price">{Number(customerContractInvoiceItem.unitPrice).toCurrencyFormat("CAD")}</div>
                <div className="table-column table-column-amount">{Number(customerContractInvoiceItem.amount).toCurrencyFormat("CAD")}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="invoice-summary">
          {Number(customerContractInvoice.serviceFeeAmount) !== 0 && (
            <div>
              <label>Frais de service</label>
              <span>{Number(customerContractInvoice.serviceFeeAmount).toCurrencyFormat("CAD")}</span>
            </div>
          )}
          <div>
            <label>Sous-total</label>
            <span>{Number(customerContractInvoice.subtotal).toCurrencyFormat("CAD")}</span>
          </div>
          <div>
            <label>Taxe ({Number(customerContractInvoice.taxRatio * 100).toLocaleString("fr-CA")}%)</label>
            <span>{Number(customerContractInvoice.taxAmount).toCurrencyFormat("CAD")}</span>
          </div>
          <div>
            <label>Total</label>
            <span>{Number(customerContractInvoice.total).toCurrencyFormat("CAD")}</span>
          </div>
          <div>
            <label>Solde dû</label>
            <span>{Number(customerContractInvoice.balance).toCurrencyFormat("CAD")}</span>
          </div>
        </div>
      </div>
    </div>
  );
});
