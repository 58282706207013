import { FunctionComponent, useEffect } from "react";
import { useAppContext, useCustomerContractChoiceContext, useCustomerContractChoiceDocumentContext, useCustomerContractContext, useProjectContext, useProjectModelContext, useRouting } from "hooks";
import { View } from "buildingBlocks";
import { ButtonToolbar, Button } from "rsuite";
import { CustomerReportByUnitNumberDocument } from "documents/CustomerReportByUnitNumberDocument";

export const ContractChoiceView: FunctionComponent = () => {
  const { navigate, toContractsView } = useRouting();
  const { projectModels, projectUnits } = useAppContext();
  const { customerContract } = useCustomerContractContext();
  const { customerContractChoice } = useCustomerContractChoiceContext();
  const { project } = useProjectContext(customerContract.projectId);
  const { projectModel } = useProjectModelContext(customerContract.projectModelId);
  const projectUnit = projectUnits.find((projectUnit) => projectUnit.id === customerContract.projectUnitId)!;
  const { download } = useCustomerContractChoiceDocumentContext("customer-report-by-unit-number-document-page", `FICHE CLIENT (${customerContractChoice.label})`);
  useEffect(() => {
    if (customerContractChoice.isDraft) {
      navigate(toContractsView());
    }
  }, [customerContractChoice, toContractsView, navigate]);
  return (
    <View title={`${project.name} - ${projectUnit.label} - ${customerContractChoice.label.toUpperCase()}`}>
      <ButtonToolbar>
        <Button appearance="primary" size="lg" onClick={download}>
          Télécharger
        </Button>
      </ButtonToolbar>
      <hr />
      <CustomerReportByUnitNumberDocument
        project={project}
        projectModels={projectModels}
        projectUnits={projectUnits}
        projectModel={projectModel}
        customerContract={customerContract}
        customerContractChoice={customerContractChoice}
      />
      <CustomerReportByUnitNumberDocument
        pageClassName="customer-report-by-unit-number-document-page"
        project={project}
        projectModels={projectModels}
        projectUnits={projectUnits}
        projectModel={projectModel}
        customerContract={customerContract}
        customerContractChoice={customerContractChoice}
      />
    </View>
  );
};
