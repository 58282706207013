import { FunctionComponent } from "react";
import { useCustomerContractContext, useRouting } from "hooks";
import { View } from "buildingBlocks";
import { List, FlexboxGrid, Button } from "rsuite";

export const ContractChoicesView: FunctionComponent = () => {
  const { navigate, toContractChoiceView } = useRouting();
  const { customerContract } = useCustomerContractContext();
  return (
    <View title="Mes révisions">
      <List className="customer-choice-list">
        {customerContract.choices.map((customerChoice) => {
          return (
            <List.Item key={customerChoice.id} index={customerChoice.index + 1}>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={18}>
                  <h2>{customerChoice.label}</h2>
                  <span>
                    {new Date(customerChoice.createdAt).toLocaleString("fr-CA", {
                      year: "numeric",
                      day: "2-digit",
                      month: "long",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={6}>
                  <Button
                    block
                    appearance="primary"
                    onClick={() => {
                      navigate(toContractChoiceView(customerContract.id, customerChoice.id));
                    }}
                  >
                    Voir la révision
                  </Button>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </List.Item>
          );
        })}
      </List>
    </View>
  );
};
