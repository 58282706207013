import { gql, useMutation } from "@apollo/client";
import { useAppContext } from "hooks";
import { useCallback } from "react";
import { CUSTOMER_CONTRACT_FRAGMENT, CUSTOMER_CONTRACT_INVOICE_FRAGMENT, ERROR_FRAGMENT } from "./fragments";
import { UpdateCustomerContractChoiceItemsInput } from "./globalTypes";
import { ConfirmCustomerContractChoiceItems, ConfirmCustomerContractChoiceItemsVariables } from "./__generated__/ConfirmCustomerContractChoiceItems";
import { CustomerContract } from "./__generated__/CustomerContract";
import { UpdateCustomerContractChoiceItems, UpdateCustomerContractChoiceItemsVariables } from "./__generated__/UpdateCustomerContractChoiceItems";
import { DownloadCustomerContract, DownloadCustomerContractVariables } from "./__generated__/DownloadCustomerContract";
import { DownloadCustomerContractUnityWebGL, DownloadCustomerContractUnityWebGLVariables } from "./__generated__/DownloadCustomerContractUnityWebGL";

// CustomerContract
const DOWNLOAD_CUSTOMER_CONTRACT_MUTATION = gql`
  ${ERROR_FRAGMENT}
  mutation DownloadCustomerContract($customerContractId: ID!) {
    payload: downloadCustomerContract(customerContractId: $customerContractId) {
      customerContractUrl
      errors {
        ...Error
      }
    }
  }
`;

export const useDownloadCustomerContractMutation = (onCompleted: (customerContractUrl: string) => void, onError: (errors: string[]) => void) => {
  const [downloadCustomerContractMutation, { loading: downloadCustomerContractLoading }] = useMutation<DownloadCustomerContract, DownloadCustomerContractVariables>(
    DOWNLOAD_CUSTOMER_CONTRACT_MUTATION
  );
  const downloadCustomerContract = useCallback(
    async (customerContractId: string) => {
      await downloadCustomerContractMutation({
        variables: {
          customerContractId,
        },
        onCompleted: ({ payload }) => {
          const { customerContractUrl, errors } = payload;
          if (customerContractUrl) {
            onCompleted(customerContractUrl);
          }
          if (errors) {
            onError(errors.map((error) => error.message));
          }
        },
      });
    },
    [downloadCustomerContractMutation, onCompleted, onError]
  );
  return { downloadCustomerContract, downloadCustomerContractLoading };
};

const DOWNLOAD_CUSTOMER_CONTRACT_UNITY_WEB_GL_MUTATION = gql`
  ${ERROR_FRAGMENT}
  mutation DownloadCustomerContractUnityWebGL($customerContractId: ID!) {
    payload: downloadCustomerContractUnityWebGL(customerContractId: $customerContractId) {
      loaderUrl
      dataUrl
      frameworkUrl
      codeUrl
      errors {
        ...Error
      }
    }
  }
`;

export const useDownloadCustomerContractUnityWebGLMutation = (
  onCompleted: (loaderUrl: string, dataUrl: string, frameworkUrl: string, codeUrl: string) => void,
  onError: (errors: string[]) => void
) => {
  const [downloadCustomerContractUnityWebGLMutation, { loading: downloadCustomerContractUnityWebGLLoading }] = useMutation<
    DownloadCustomerContractUnityWebGL,
    DownloadCustomerContractUnityWebGLVariables
  >(DOWNLOAD_CUSTOMER_CONTRACT_UNITY_WEB_GL_MUTATION);
  const downloadCustomerContractUnityWebGL = useCallback(
    async (customerContractId: string) => {
      await downloadCustomerContractUnityWebGLMutation({
        variables: {
          customerContractId,
        },
        onCompleted: ({ payload }) => {
          const { loaderUrl, dataUrl, frameworkUrl, codeUrl, errors } = payload;
          if (loaderUrl && dataUrl && frameworkUrl && codeUrl) {
            onCompleted(loaderUrl, dataUrl, frameworkUrl, codeUrl);
          }
          if (errors) {
            onError(errors.map((error) => error.message));
          }
        },
      });
    },
    [downloadCustomerContractUnityWebGLMutation, onCompleted, onError]
  );
  return { downloadCustomerContractUnityWebGL, downloadCustomerContractUnityWebGLLoading };
};

// CustomerContractChoiceItem
const UPDATE_CUSTOMER_CONTRACT_CHOICE_ITEMS_MUTATION = gql`
  ${CUSTOMER_CONTRACT_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation UpdateCustomerContractChoiceItems($customerContractId: ID!, $customerContractChoiceId: ID!, $input: UpdateCustomerContractChoiceItemsInput!) {
    payload: updateCustomerContractChoiceItems(customerContractId: $customerContractId, customerContractChoiceId: $customerContractChoiceId, input: $input) {
      customerContract {
        ...CustomerContract
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useUpdateCustomerContractChoiceItemsMutation = (onCompleted: (customerContract: CustomerContract) => void, onError: (errors: string[]) => void) => {
  const { upsertCustomerContract: upsertContract } = useAppContext();
  const [updateCustomerContractChoiceItemsMutation, { loading: updateCustomerContractChoiceItemsLoading }] = useMutation<UpdateCustomerContractChoiceItems, UpdateCustomerContractChoiceItemsVariables>(
    UPDATE_CUSTOMER_CONTRACT_CHOICE_ITEMS_MUTATION
  );
  const updateCustomerContractChoiceItems = useCallback(
    async (customerContractId: string, customerContractChoiceId: string, input: UpdateCustomerContractChoiceItemsInput) => {
      await updateCustomerContractChoiceItemsMutation({
        variables: {
          customerContractId,
          customerContractChoiceId,
          input,
        },
        onCompleted: ({ payload }) => {
          const { customerContract, errors } = payload;
          if (customerContract) {
            upsertContract(customerContract);
            onCompleted(customerContract);
          }
          if (errors) {
            onError(errors.map((error) => error.message));
          }
        },
      });
    },
    [updateCustomerContractChoiceItemsMutation, onCompleted, onError, upsertContract]
  );
  return { updateCustomerContractChoiceItems, updateCustomerContractChoiceItemsLoading };
};

const CONFIRM_CUSTOMER_CONTRACT_CHOICE_ITEMS_MUTATION = gql`
  ${CUSTOMER_CONTRACT_FRAGMENT}
  ${CUSTOMER_CONTRACT_INVOICE_FRAGMENT}
  ${ERROR_FRAGMENT}
  mutation ConfirmCustomerContractChoiceItems($customerContractId: ID!, $customerContractChoiceId: ID!) {
    payload: confirmCustomerContractChoiceItems(customerContractId: $customerContractId, customerContractChoiceId: $customerContractChoiceId) {
      customerContract {
        ...CustomerContract
      }
      customerContractInvoice {
        ...CustomerContractInvoice
      }
      errors {
        ...Error
      }
    }
  }
`;

export const useConfirmCustomerChoiceItemsMutation = (onCompleted: (customerContractInvoiceId: string) => void, onError: (errors: string[]) => void) => {
  const { upsertCustomerContract: upsertContract, upsertCustomerContractInvoice: upsertInvoice } = useAppContext();
  const [confirmCustomerContractChoiceItemsMutation, { loading: confirmCustomerContractChoiceItemsLoading }] = useMutation<
    ConfirmCustomerContractChoiceItems,
    ConfirmCustomerContractChoiceItemsVariables
  >(CONFIRM_CUSTOMER_CONTRACT_CHOICE_ITEMS_MUTATION);
  const confirmCustomerContractChoiceItems = useCallback(
    async (customerContractId: string, customerContractChoiceId: string) => {
      await confirmCustomerContractChoiceItemsMutation({
        variables: {
          customerContractId,
          customerContractChoiceId,
        },
        onCompleted: ({ payload }) => {
          const { customerContract, customerContractInvoice, errors } = payload;
          if (customerContract && customerContractInvoice) {
            upsertContract(customerContract);
            upsertInvoice(customerContractInvoice);
            onCompleted(customerContractInvoice.id);
          }
          if (errors) {
            onError(errors.map((error) => error.message));
          }
        },
      });
    },
    [confirmCustomerContractChoiceItemsMutation, onCompleted, onError, upsertContract, upsertInvoice]
  );
  return { confirmCustomerContractChoiceItems, confirmCustomerContractChoiceItemsLoading };
};
